import React, { useState } from "react";
import { ShortTextCell } from "../../../../../../Partials/Layout/CRUD/ShortTextCell/ShortTextCell";
import SelectInput from "../../../../../../Partials/Forms/FormInputs/SelectInput/SelectInput";
import SelectOption from "../../../../../../Partials/Forms/FormInputs/SelectInput/SelectOption/SelectOption";
import { Link } from "react-router-dom";
import { useGetInfo } from "antopolis-react-utils/hooks";
import { MANAGE_BANK_DETAILS_API } from "../../../../../../../Utilities/APIs/APIs";
import { useAxiosInstance } from "../../../../../../../Utilities/Hooks/useAxiosInstance";
import { ViewButton } from "../../../../../../Partials/Layout/CRUD/CRUDButtons/CRUDButtons";
import { IconEye } from "antopolis-react-icons";
const RowItem = ({ e }) => {
  const axiosInstance = useAxiosInstance();
  const [toggleFetch, setToggleFetch] = useState(false);
  //bank details
  const { info: bankDetails } = useGetInfo({
    axiosInstance: axiosInstance,
    api: MANAGE_BANK_DETAILS_API + "/investorBankDetails/" + e?._id,
    toggleFetch,
  });

  return (
    <tr key={e._id}>
      <td>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
          }}
        >
          <img
            style={{
              height: "30px",
              width: "30px",
              borderRadius: "50%",
            }}
            src={
              e.image
                ? process.env.REACT_APP_SPACES_URL + e.image
                : `https://ui-avatars.com/api/?name=${e.name}&background=random&length=1`
            }
            alt=""
          />
          <p>{e.name}</p>
        </div>
      </td>
      <ShortTextCell text={e.email} />
      {/* <td className="whitespace-nowrap cursor-pointer hover:scale-105" style={{ textAlign: "center", fontSize: "16px" }} onClick={handleBankDetails} >UCB</td> */}
      <td
        className="whitespace-nowrap cursor-pointer"
        style={{ textAlign: "center", fontSize: "16px" }}
      >
        <SelectInput setState={() => {}}>
          {bankDetails?.bankDetails?.length ? (
            bankDetails?.bankDetails?.map((bank) => (
              <SelectOption
                key={bank._id}
                optionValue={bank._id}
                optionText={bank.bankName}
              />
            ))
          ) : (
            <SelectOption
              optionValue={"No accounts"}
              optionText={"No accounts"}
            />
          )}
        </SelectInput>
      </td>
      <td
        className="whitespace-nowrap cursor-pointer hover:scale-105 relative"
        style={{ textAlign: "center", fontSize: "16px" }}
      >
        <Link
          className="inset-0 absolute flex items-center justify-center"
          to={`/main/transactions/${e._id}`}
        >
          <IconEye />
        </Link>
      </td>
      {/* <SHortTextCell text={e.bank} /> */}
      {/* <ShortTextCell text={e.level} /> */}
    </tr>
  );
};

export default RowItem;
