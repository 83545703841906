import { useContext, useState } from "react";
import { LayoutContext } from "../../../../Contexts/GlobalContexts/LayoutContext";
import { useAxiosInstance } from "../../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../../Partials/Forms/Form";
import ShortTextInput from "../../../Partials/Forms/FormInputs/ShortTextInput/ShortTextInput";
import FormSubmitButton from "../../../Partials/Forms/FormSubmitButton/FormSubmitButton";

function CreateCompany({ api, targetID, setShowCreateForm }) {
  const [name, setName] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false); // For loading state

  const { triggerFetch } = useContext(LayoutContext);
  const axiosInstance = useAxiosInstance();

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true); // Start loading
    setError(""); // Clear any previous errors

    const itemData = {
      name,
    };

    try {
      
      const response = await axiosInstance.post(api + "company", itemData);
      if (response) {
        triggerFetch();
        setShowCreateForm(false); // Hide the form on success
      }
    } catch (error) {
      setError(
        "An error occurred while creating the company. Please try again."
      );
    } finally {
      setLoading(false); // Stop loading after submission
    }
  }

  return (
    <div className="create_form">
      {error && <div className="error_message text-red-500 mb-2">{error}</div>} {/* Error message */}

      <Form onSubmit={handleSubmit}>
        <ShortTextInput
          label="Company Name"
          value={name}
          placeholder="Enter Company Name"
          setState={setName}
          disabled={loading} // Disable input while loading
        />

        <FormSubmitButton text={loading ? "Creating..." : "Create Company"} disabled={loading} />
      </Form>
    </div>
  );
}

export default CreateCompany;
