import { FaInfoCircle } from "react-icons/fa"; // Importing Font Awesome icon
import { useEffect, useMemo, useState } from "react";
import { takaFormatter } from "../../../../../../../../Utilities/HelperFunctions/takaFormatter";
import BackButton from "../../../../../../../Partials/Elements/BackButton/BackButton";
import { MANAGE_Investors_API } from "../../../../../../../../Utilities/APIs/APIs";
import { useAxiosInstance } from "../../../../../../../../Utilities/Hooks/useAxiosInstance";

function ProfitDisplay({ title, profit, tooltipText }) {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div className="flex flex-col justify-center gap-4 relative 2xl:px-20">
      <h3 className="text-2xl flex items-center gap-2 text-gray-300 w-max ">
        {title}
        <FaInfoCircle
          className="text-gray-300 cursor-pointer"
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
        />
        {showTooltip && (
          <div className="absolute -top-10 mb-2 bg-gray-700 text-white text-lg rounded py-1 px-2 w-max">
            {tooltipText}
          </div>
        )}
      </h3>
      <p className="py-1 text-2xl xl:text-3xl 2xl:text-5xl rounded p-4 text-white w-max">
        {takaFormatter(profit)}
      </p>
    </div>
  );
}

export default function InvestmentsSummary({
  activeInvestments,
  activeDisbursedInvestments,
  investorId,
}) {
  const [investor, setInvestor] = useState(null);

  const axiosInstance = useAxiosInstance();

  const netProfit = useMemo(() => {
    return activeInvestments.reduce((total, investment) => {
      const { amount, rate, tenure } = investment;
      const profit = amount * (rate / 100) * (tenure / 365);
      return total + profit;
    }, 0);
  }, [activeInvestments]);

  const disbursedProfit = useMemo(() => {
    return activeDisbursedInvestments.reduce((total, investment) => {
      const { amount, rate, tenure } = investment;
      const profit = amount * (rate / 100) * (tenure / 365);
      return total + profit;
    }, 0);
  }, [activeDisbursedInvestments]);

  useEffect(() => {
    async function fetchInvestorData() {
      const response = await axiosInstance.get(
        MANAGE_Investors_API + "getSingleInvestor/" + investorId
      );
      if (response && response.data) {
        setInvestor(response.data);
      }
    }
    fetchInvestorData();
  }, [axiosInstance, investorId]);

  return (
    <div className="flex justify-center items-center">
      <BackButton />

      <div className="">
        <div className="flex items-center gap-10 py-10 px-20">
          <div className="rounded-full">
            <img
              src={
                investor?.image
                  ? process.env.REACT_APP_SPACES_URL + investor?.image
                  : `https://ui-avatars.com/api/?name=${investor?.name}&background=random&length=1`
              }
              alt="investor-image"
              className="2xl:w-60 2xl:h-60  xl:h-52 xl:w-52 h-44 w-44 rounded-full object-cover object-center"
            />
          </div>

          <div className="space-y-2 font-medium text-xl text-[#fff]">
            <p className="text-4xl xl:text-5xl 2xl:text-6xl">
              {investor?.name}
            </p>
            <div className="flex gap-2">
              <p>{investor?.designation},</p>
              <p>{investor?.company}</p>
            </div>
            <p>{investor?.email}</p>
            <p>{investor?.phone ? `${investor?.phone}` : "No Phone Number"}</p>
          </div>
        </div>
      </div>
      <div className="w-max mx-auto p-10 rounded-md mb-8 grid grid-cols-2 gap-20 relative">
        <ProfitDisplay
          title="Disbursed Profit"
          profit={investor?.startingProfit + disbursedProfit}
          tooltipText="This is the profit already disbursed."
        />
        <ProfitDisplay
          title="Total Profit"
          profit={investor?.startingProfit + netProfit}
          tooltipText="This is the total profit including disbursed profit."
        />
      </div>
    </div>
  );
}
