import FormTop from '../../../Partials/Forms/FormTop/FormTop'
import Navigate from '../../../Partials/Navigate/Navigate'
import LoginForm from './LoginForm/LoginForm'

import '../Auth.css'

function Login() {

    return (
        <div className="form_wrapper">

            <FormTop title='Login to Wisergates' />
            <LoginForm />
            <Navigate route='/auth/forgotPassword' text='Forgot Password?' />

        </div>
    )
}

export default Login