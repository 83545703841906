import React from 'react'
import { ScreenHolder } from '../../../../Partials/Layout/ScreenHolder/ScreenHolder'
import { NavCardList } from '../../../../Partials/Layout/NavCardList/NavCardList'
import { NavCard } from '../../../../Partials/Layout/NavCardList/NavCard/NavCard'
import { RiFacebookBoxFill, RiImage2Fill, RiPercentFill, RiPriceTag2Fill, RiSettings2Fill, RiTruckFill } from 'react-icons/ri'

function WebsiteContent() {
    return (
        <ScreenHolder>
            <NavCardList numOfCards={'six'}>
                <NavCard cardName={'Hero Sliders'} navCardLink='/main/nanoAsset/HeroSliders' ><RiImage2Fill /></NavCard>
                {/* <NavCard cardName={'Testimonials'} navCardLink='/main/Testimonials' ><RiSettings2Fill /></NavCard>
                <NavCard cardName={'Social Links'} navCardLink='/main/socialLinks' ><RiFacebookBoxFill /></NavCard> */}

            </NavCardList>
        </ScreenHolder>
    )
}

export default WebsiteContent
