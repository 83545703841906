import { useEffect, useState } from "react";
import { useAxiosInstance } from "../../../../../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../../../../../Partials/Forms/Form";
import EmailInput from "../../../../../../Partials/Forms/FormInputs/EmailInput/EmailInput";
import ShortTextInput from "../../../../../../Partials/Forms/FormInputs/ShortTextInput/ShortTextInput";
import NumberInput from "../../../../../../Partials/Forms/FormInputs/NumberInput/NumberInput";
import FormSubmitButton from "../../../../../../Partials/Forms/FormSubmitButton/FormSubmitButton";
import {
  MANAGE_Company_API,
  MANAGE_Investors_API,
} from "../../../../../../../Utilities/APIs/APIs";
import DateInput from "../../../../../../Partials/Forms/FormInputs/DateInput/DateInput";
import { useGetInfo } from "antopolis-react-utils/hooks";
import SelectOption from "../../../../../../Partials/Forms/FormInputs/SelectInput/SelectOption/SelectOption";
import SelectInput from "../../../../../../Partials/Forms/FormInputs/SelectInput/SelectInput";

export default function CreateInvestment({
  api,
  targetID,
  setShowEditSection,
  triggerFetch,
}) {
  const axiosInstance = useAxiosInstance();
  const { info: companies } = useGetInfo({
    axiosInstance: axiosInstance,
    api: MANAGE_Company_API + "getActiveCompanies",
  });

  const [company, setCompany] = useState("");
  const [amount, setAmount] = useState(null);
  const [rate, setRate] = useState(null);
  const [date, setDate] = useState(new Date());
  const [tenure, setTenure] = useState(null);
  const [loading, setLoading] = useState(false); // For loading state
  const [formError, setFormError] = useState(null); // For error handling

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true); // Start loading
    setFormError(null); // Reset any previous errors

    const itemData = {
      investor: targetID,
      company,
      amount,
      date,
      rate,
      tenure,
    };

    try {
      const response = await axiosInstance.post(
        api + "makeSingleInvestment",
        itemData
      );

      if (response?.data?.investmentData) {
        setCompany("");
        setAmount(0);
        setDate(new Date());
        setRate(0);
        setTenure(0);
        setShowEditSection(false);
        triggerFetch();
      }
    } catch (error) {
      setFormError("Error making investment. Please try again.");
    } finally {
      setLoading(false); // Stop loading after submission
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      {formError && <div className="text-red-500 mb-2">{formError}</div>} {/* Display form error */}

      <SelectInput
        label={" Company"}
        value={company}
        placeholder={"Please Enter Company Name"}
        setState={setCompany}
        disabled={loading} // Disable while loading
      >
        <SelectOption optionValue={""} optionText={"Select Company"} />
        {companies?.length > 0 &&
          companies?.map(({ _id, name }) => (
            <SelectOption key={_id} optionValue={_id} optionText={name} />
          ))}
      </SelectInput>
      <DateInput
        label={"Investment Date"}
        value={date}
        placeholder={"Please Enter Date"}
        setState={setDate}
        disabled={loading} // Disable while loading
      />
      <NumberInput
        label={"Amount"}
        value={amount}
        placeholder={"Please Enter Amount (in tk)"}
        setState={setAmount}
        disabled={loading} // Disable while loading
      />
      <NumberInput
        label={"Annualized ROI (%)"}
        value={rate}
        placeholder={"Please Enter ROI"}
        setState={setRate}
        disabled={loading} // Disable while loading
      />
      <NumberInput
        label={"Tenure (Days)"}
        value={tenure}
        placeholder={"Please Enter Tenure (in days)"}
        setState={setTenure}
        disabled={loading} // Disable while loading
      />
      <FormSubmitButton text={loading ? "Processing..." : "Make Investment"} disabled={loading} />
    </Form>
  );
}
