import { useParams } from 'react-router-dom'
import FormTop from '../../../Partials/Forms/FormTop/FormTop'
import RecoverPasswordForm from './RecoverPasswordForm/RecoverPasswordForm'

function RecoverPassword() {

  const { token } = useParams()

  return (

    <div className="form_wrapper">
      <FormTop
        title='Recover Password'
      />
      <RecoverPasswordForm token={token} />
    </div>

  )
}

export default RecoverPassword