import { useEffect, useState } from "react"

import { useAxiosInstance } from "../../../../../../Utilities/Hooks/useAxiosInstance"
import "./InviteTokenInvestors.css"

function DeleteInvite({ employee, api, targetID, setShowDeleteSection, setShowModal, triggerFetch }) {

    const [email, setEmail] = useState('')
    const axiosInstance = useAxiosInstance()

    useEffect(() => {
        async function fetchAndSetEmail() {
            const { data } = await axiosInstance.get(api + "getSingleInvite/" + targetID)
            setEmail(data.email)
        }

        fetchAndSetEmail()

    }, [targetID, axiosInstance])


    async function handleDelete() {
        const response = await axiosInstance.delete(api + "deleteInvite/" + targetID)

        if (response) {
            setEmail('')

            setShowDeleteSection(false)
            triggerFetch()
        }
    }
    return (
        <div className='modal_delete_content'>

            <p>Are you sure you want to delete invite for {email ? email : ''}?</p>

            <div className="modal_group_buttons">
                <button
                    className="delete" onClick={() => {
                        handleDelete()
                    }}
                >Delete</button>

                <button className="cancel" onClick={() => {
                    setShowModal(false)
                    setShowDeleteSection(false)
                }}>Cancel</button>
            </div>

        </div>
    )
}

export default DeleteInvite