import { Link } from "react-router-dom"
import './Navigate.css'

function Navigate({ route, text }) {
    return (
        <div className='navigate'>
            <Link to={route}>
                <p>{text}</p>
            </Link>
        </div>
    )
}

export default Navigate