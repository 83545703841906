import './InputBoilerplate.css';
import { useEffect, useRef } from 'react';

function InputBoilerplate({ label, type, value, placeholder, setState, inputIsTextArea, isDisabled }) {
  const inputRef = useRef(null);

  useEffect(() => {
    const input = inputRef.current;

    if (input && type === 'number') {
      const preventScroll = (e) => e.preventDefault();
      const preventArrowKeys = (e) => {
        if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
          e.preventDefault();
        }
      };

      input.addEventListener('wheel', preventScroll);

      input.addEventListener('keydown', preventArrowKeys);

      return () => {
        input.removeEventListener('wheel', preventScroll);
        input.removeEventListener('keydown', preventArrowKeys);
      };
    }
  }, [type]);

  return (
    <div className="input_group">
      <label className="input_field_label caption bold">{label}</label>
      {!inputIsTextArea &&
        <input
          ref={inputRef} // Attach ref to the input element
          className="input_field"
          type={type}
          value={value}
          placeholder={placeholder}
          onChange={e => setState(e.target.value)}
          disabled={isDisabled}
        />
      }
      {inputIsTextArea &&
        <textarea
          className="input_field"
          value={value}
          placeholder={placeholder}
          onChange={e => setState(e.target.value)}
        />
      }
    </div>
  );
}

export default InputBoilerplate;
