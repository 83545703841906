import React from 'react'
import ReactDOM from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'
import routes from './Routes'
import { AppContextProvider } from './Contexts/AppContext'

import './index.css'

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <AppContextProvider>
      <RouterProvider router={routes} />
    </AppContextProvider>
  </React.StrictMode>,
)
