import { useContext, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { AuthContext } from "../../../Contexts/GlobalContexts/AuthContext";

function AuthScreens() {

    const navigate = useNavigate()

    const { employee } = useContext(AuthContext)

    useEffect(() => {
        if (employee) {
            navigate('/main/dashboard')
        }
    }, [employee, navigate])

    return (
        <Outlet />
    )
}

export default AuthScreens