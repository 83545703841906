import { createContext, useContext, useState } from "react";
import { LayoutContext } from "./LayoutContext";

const AuthContext = createContext({})

function AuthContextProvider({ children }) {

    const { setIsLoading } = useContext(LayoutContext)

    const [error, setError] = useState("")
    const [employee, setEmployee] = useState(JSON.parse(localStorage.getItem('employee')))

    async function register(employeeData, registerApi, axiosAuthInstance) {

        try {
            const response = await axiosAuthInstance.post(registerApi, employeeData);

            if (response.data) {
                setEmployee(response.data)
                localStorage.setItem('employee', JSON.stringify(response.data))
                setError("")
            }
            return response.data

        } catch (error) {
            console.log(error.message);
            setError(error?.response?.data?.message)
        }
    }

    async function login(employeeData, loginApi, axiosAuthInstance) {
        try {
            setIsLoading(true)
            const response = await axiosAuthInstance.post(loginApi, employeeData);

            if (response.data) {
                localStorage.setItem('employee', JSON.stringify(response.data))
                setEmployee(response.data)
                setIsLoading(false)
                setError("")
            }

        } catch (error) {
            setIsLoading(false)
            setError(error?.response?.data?.message)
        }
    }

    function logout() {
        setEmployee(null)
        localStorage.removeItem('employee')
    }


    return (
        <AuthContext.Provider
            value={{
                register,
                login,
                logout,
                error,
                setError,
                employee,
                setEmployee
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export {
    AuthContextProvider,
    AuthContext
}