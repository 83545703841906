import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { IconMenu } from 'antopolis-react-icons'
import SidebarContext from '../SidebarContext'
import logoWide from '../logo-wide.svg'
import { Image } from '../../../Elements/Image/Image';
import { AuthContext } from '../../../../../Contexts/GlobalContexts/AuthContext'
import { LayoutContext } from '../../../../../Contexts/GlobalContexts/LayoutContext'

import './Navbar.css'

function Navbar() {

    const { setShowSidebar } = useContext(SidebarContext)
    const { setExpandSidebar } = useContext(LayoutContext)
    const { employee } = useContext(AuthContext)

    return (
        <div className='navbar'>
            <button
                onClick={() => {
                    setShowSidebar(true)
                    setExpandSidebar(true)
                }}
            >
                <IconMenu />
            </button>
            {/* <Link to="/" className='logo_wrapper'>
                <Image
                    imgLink={logoWide}
                    imgAlt={'brand'}
                    className={'w_100'}
                    assetOrWeb
                />
            </Link> */}
            <h3 style={{color: 'white'}}> Wisergates</h3>
            <div className="user_box">
                <Image
                    imgLink={employee?.dp}
                    imgAlt={'user thumb'}
                    className={''}
                    assetOrWeb
                />
            </div>
        </div>
    )
}

export default Navbar