import { useContext, useState, useMemo } from "react";
import { useUseOrientation } from "../../../../Utilities/Hooks/useUseOrientation.js";
import { useAxiosInstance } from "../../../../Utilities/Hooks/useAxiosInstance.js";
import { LayoutContext } from "../../../../Contexts/GlobalContexts/LayoutContext.js";
import { useGetInfo } from "antopolis-react-utils/hooks";
import { CreateButton } from "../../../Partials/Layout/CRUD/CreateButton/CreateButton.js";
import { CRUDTable } from "../../../Partials/Layout/CRUD/CRUDTable/CRUDTable.js";
import { CRUDTableHeader } from "../../../Partials/Layout/CRUD/CRUDTableHeader/CRUDTableHeader.js";
import { CRUDth } from "../../../Partials/Layout/CRUD/CRUDth/CRUDth.js";
import {
  CRUDButton,
  DeleteButton,
  EditButton,
  ViewButton,
} from "../../../Partials/Layout/CRUD/CRUDButtons/CRUDButtons.js";
import { Modal } from "../../../Partials/Elements/Modal/Modal.js";
import { IconThreeDots } from "antopolis-react-icons";
import ViewCompany from "./ViewCompany.js";
import CreateCompany from "./CreateCompany.js";
import UpdateCompany from "./UpdateCompany.js";
import { useNavigate } from "react-router-dom";
import { FaBriefcase } from "react-icons/fa";
import BackButton from "../../../Partials/Elements/BackButton/BackButton.js";
import DeleteCompany from "./DeleteCompany.js";
import { BiTrash } from "react-icons/bi";
import SelectInput from "../../../Partials/Forms/FormInputs/SelectInput/SelectInput";
import SelectOption from "../../../Partials/Forms/FormInputs/SelectInput/SelectOption/SelectOption";
import { TbTrashOff } from "react-icons/tb";
import UnArchiveCompany from "./UnArchiveCompany.js";
import { MANAGE_Company_API } from "../../../../Utilities/APIs/APIs.js";


// Function to export data as CSV
const exportToCSV = (companies) => {
  const headers = ["Name", "Status"];
  const rows = companies.map((company) => [
    company.name,
    company.isActive ? "Active" : "Inactive",
  ]);

  const csvContent =
    "data:text/csv;charset=utf-8," +
    [headers.join(","), ...rows.map((row) => row.join(","))].join("\n");

  const encodedUri = encodeURI(csvContent);
  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", "companies.csv");
  document.body.appendChild(link);

  link.click();
  document.body.removeChild(link);
};

function Companies({ api }) {
  const [showActionButton, setShowActionButton] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [showUnArchiveSection, setShowUnArchiveSection] = useState(false);
  // const [toggleFetch, setToggleFetch] = useState(false);


  const [targetID, setTargetID] = useState(null);
  const [filter, setFilter] = useState("active");
  const { triggerFetch } = useContext(LayoutContext);


  const { portrait } = useUseOrientation();
  const axiosInstance = useAxiosInstance();
  const navigate = useNavigate();

  const { info: companies } = useGetInfo({
    axiosInstance: axiosInstance,
    api: api + "getAllCompanies",
    toggleFetch : triggerFetch,
  });

  const handleFilterChange = (value) => {
    setFilter(value);
  };

  const filteredCompanies = useMemo(() => {
    if (!companies) return [];
    return companies
      .filter((company) => {
        if (filter === "active") return company.isActive;
        if (filter === "archived") return !company.isActive;
        return true;
      })
      .sort((a, b) => b.isActive - a.isActive);
  }, [companies, filter]);

  return (
    <div className="screen_wrapper">
      <div className="screen_header">
        <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
          <BackButton />
          <h1 className="screen_heading">
            Companies ({filteredCompanies?.length} in total)
          </h1>
        </div>
        <SelectInput
          label={""}
          value={filter}
          placeholder={"Please Select Filter"}
          setState={handleFilterChange}
        >
          <SelectOption optionValue={"active"} optionText={"Active"} />
          <SelectOption optionValue={"archived"} optionText={"Archived"} />
          {/* <SelectOption optionValue={"all"} optionText={"All"} /> */}
        </SelectInput>
        <div style={{ display: "flex", gap: "20px" }}>
          <CreateButton
            screenTopicSingular={"Company"}
            setShowCreateForm={setShowCreateForm}
          />

          {/* Export to CSV button */}
          <CRUDButton handleClick={() => exportToCSV(filteredCompanies)}>
            <p style={{ fontSize: "16px", color: "#FFE09D", textAlign: "center" }}>
              Export To CSV
            </p>
          </CRUDButton>
        </div>
      </div>
      <CRUDTable>
        <CRUDTableHeader>
          <CRUDth align={"left"} th="Name" />
          <CRUDth align={"right"} th="Actions" />
        </CRUDTableHeader>
        <tbody>
          {filteredCompanies?.map((company) => (
            <tr key={company._id}>
              <td className="action_buttons_wrapper">
                <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                  <span
                    style={{
                      backgroundColor: company.isActive ? "green" : "red",
                      height: "10px",
                      width: "10px",
                      borderRadius: "50%",
                      margin: "auto 5px",
                    }}
                  />
                  <p>{company.name}</p>
                </div>
              </td>
              <td>
                <div
                  style={{ textAlign: "center" }}
                  className="action_buttons_wrapper"
                >
                  <button
                    className="three_dots"
                    onClick={() => {
                      if (targetID === company._id) {
                        setTargetID("");
                      } else {
                        setShowActionButton(true);
                        setTargetID(company._id);
                      }
                    }}
                  >
                    <IconThreeDots />
                  </button>
                  {!portrait && (
                    <div
                      style={{ justifyContent: "end" }}
                      className="action_buttons"
                    >
                      <EditButton
                        setShowUpdateForm={setShowUpdateForm}
                        targetID={company._id}
                        setTargetID={setTargetID}
                        tooltipId="company-edit-btn"
                        tooltipContent="Edit"
                      />
                      <CRUDButton
                        handleClick={() =>
                          navigate(`/main/companyInvestments/${company._id}`)
                        }
                        tooltipId="single-manage-investment-btn"
                        tooltipContent="Manage Investment"
                      >
                        <FaBriefcase size={25} />
                      </CRUDButton>
                      {
                        company.isActive && <CRUDButton
                          handleClick={() => {
                            setShowDeleteSection(true);
                            setTargetID(company._id);
                          }}
                          tooltipId="company-archive-btn"
                          tooltipContent="Archive"
                        >
                          <div className="text-red-500 flex items-center justify-center">
                            <BiTrash size={25} />
                          </div>
                        </CRUDButton>
                      }
                      {
                        !company.isActive && <CRUDButton
                          handleClick={() => {
                            setShowUnArchiveSection(true);
                            setTargetID(company._id);
                          }}
                          tooltipId="company-activate-btn"
                          tooltipContent="Active"
                        >
                          <div className=" flex items-center justify-center">
                            <TbTrashOff size={25} />
                          </div>

                        </CRUDButton>
                      }
                    </div>
                  )}
                  {portrait && showActionButton && targetID === company._id && (
                    <div className="action_buttons">
                      <ViewButton
                        setShowViewSection={setShowViewSection}
                        targetID={company._id}
                        setTargetID={setTargetID}
                      />
                      <EditButton
                        setShowUpdateForm={setShowUpdateForm}
                        targetID={company._id}
                        setTargetID={setTargetID}
                        setShowActionButton={setShowActionButton}
                      />
                    </div>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </CRUDTable>

      {showCreateForm && (
        <Modal
          extraClass={"small"}
          modalHeading={`Create Company`}
          setShowModalContent={setShowCreateForm}
        >
          <CreateCompany
            setShowCreateForm={setShowCreateForm}
            api={"manageCompanies/"}
            targetID={targetID}
          />
        </Modal>
      )}
      {showUpdateForm && (
        <Modal
          extraClass={"small"}
          modalHeading={`Update Company`}
          setShowModalContent={setShowUpdateForm}
        >
          <UpdateCompany
            setShowUpdateForm={setShowUpdateForm}
            targetID={targetID}
            api={`${api}company/`}
          />
        </Modal>
      )}
      {showViewSection && (
        <Modal
          extraClass={"small"}
          modalHeading={`View Company`}
          setShowModalContent={setShowViewSection}
        >
          <ViewCompany targetID={targetID} api={`${api}/company/`} />
        </Modal>
      )}
      {showDeleteSection && (
        <Modal
          extraClass={"small"}
          modalHeading={`Archive Company`}
          setShowModalContent={setShowDeleteSection}
        >
          <DeleteCompany
            setShowDeleteSection={setShowDeleteSection}
            targetID={targetID}
            api={`${api}company/`}
            triggerFetch={triggerFetch}
          />
        </Modal>
      )}
      {
        showUnArchiveSection &&
        <Modal
          extraClass={"small"}
          modalHeading={`Unarchive Company`}
          setShowModalContent={setShowUnArchiveSection}
        >
          <UnArchiveCompany
            api={MANAGE_Company_API}
            setShowUnArchiveSection={setShowUnArchiveSection}
            targetID={targetID}
            triggerFetch={triggerFetch}
          />
        </Modal>
      }
    </div>
  );
}

export default Companies;
