import "./SelectOption.css";

function SelectOption({ optionValue, optionText, hidden, disabled }) {
  return (
    <option
      className="text-black"
      value={optionValue}
      hidden={hidden}
      disabled={disabled}
    >
      {optionText}
    </option>
  );
}

export default SelectOption;
