import { MANAGE_Tokens_API } from "../../../../../Utilities/APIs/APIs";
import { useAxiosInstance } from "../../../../../Utilities/Hooks/useAxiosInstance";

function UnArchiveTokenModal({
  targetID,
  setShowUnArchiveSection,
  setShowModal,
  triggerFetch,
}) {
  const axiosInstance = useAxiosInstance();

  // const { triggerFetch } = useContext(LayoutContext);

  async function handleDelete() {
    const response = await axiosInstance.put(
      MANAGE_Tokens_API + "unArchiveSingleToken/" + targetID
    );
    if (response) {
      triggerFetch();
      setShowUnArchiveSection(false);
    }
  }
  return (
    <div className="modal_delete_content">
      <p className="text-gray-200">
        Are you sure you want to unarchive this hero slider?
      </p>

      <div className="modal_group_buttons">
        <button
          className="submit"
          onClick={() => {
            handleDelete();
          }}
        >
          Unarchive
        </button>

        <button
          className="cancel"
          onClick={() => {
            setShowModal(false);
            setShowUnArchiveSection(false);
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  );
}

export default UnArchiveTokenModal;
