import { useContext } from "react";
import SidebarContext from "../SidebarContext";

import SidebarTop from "./SidebarTop/SidebarTop";
import SidebarProfile from "./SidebarProfile/SidebarProfile";

import SidebarItems from "./SidebarItems/SidebarItems";
import { LayoutContext } from "../../../../../Contexts/GlobalContexts/LayoutContext";

import "./Sidebar.css";
import NavItems from "../../../NavItems/NavItems";

function Sidebar() {
  const { showSidebar } = useContext(SidebarContext);
  const { expandSidebar, setExpandSidebar } = useContext(LayoutContext);

  return (
    <div
      className={`sidebar ${expandSidebar ? "expanded" : ""} ${showSidebar ? "show" : ""
        }`}
      onMouseEnter={() => setExpandSidebar(true)}
      onMouseLeave={() => setExpandSidebar(false)}
    >
      <div className={`sidebar_wrapper ${expandSidebar ? 'sidebar_wrapper_expanded' : ''}`}>
        <SidebarTop expandSidebar={expandSidebar} />
        <SidebarItems>
          <NavItems />
        </SidebarItems>
        <SidebarProfile />
      </div>
    </div>
  );
}

export default Sidebar;

