import { createBrowserRouter } from 'react-router-dom';


import MainScreens from './Components/Screens/MainScreens/MainScreens';
import MainRoutes from './Components/Screens/MainScreens/MainRoutes';
import authRoutes from './Components/Screens/AuthScreens/AuthRoutes';
import AuthScreens from './Components/Screens/AuthScreens/AuthScreens';
import App from './App';


const routes = createBrowserRouter([
  {
    path: '/',
    element: <App />,
     children: [
      {
        path: '/main',
        element: <MainScreens />,
        children: MainRoutes
      },
      {
        path: '/auth',
        element: <AuthScreens />,
        children: authRoutes
      }
    ]
  }

])

export default routes