import { useState, useEffect } from "react";
import { useAxiosInstance } from "../../../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../../../Partials/Forms/Form";
import SelectInput from "../../../../Partials/Forms/FormInputs/SelectInput/SelectInput"; // For Assign to Investor
import FormSubmitButton from "../../../../Partials/Forms/FormSubmitButton/FormSubmitButton";
import { useGetInfo } from "antopolis-react-utils/hooks";
import { MANAGE_INVESTOR_USERS_INVESTORS_API, MANAGE_Investors_API } from "../../../../../Utilities/APIs/APIs";
import SelectOption from "../../../../Partials/Forms/FormInputs/SelectInput/SelectOption/SelectOption";

function AssignInvestorUser({ api, setShowUpdateForm, triggerFetch, targetID }) {
    const [assignedTo, setAssignedTo] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isFetching, setIsFetching] = useState(true);
    const axiosInstance = useAxiosInstance();
    const [toggleFetch, setToggleFetch] = useState(false);

    const [error, setError] = useState(null);

    // Fetch the list of investors
    const { info: investorOptions } = useGetInfo({
        axiosInstance: axiosInstance,
        api:  MANAGE_Investors_API + "getAllInvestors",
        toggleFetch,
    });

    const { info: assignedInvestor } = useGetInfo({
        axiosInstance: axiosInstance,
        api:  MANAGE_INVESTOR_USERS_INVESTORS_API + "getAllInvestorOfInvestorUser/" + targetID,
        toggleFetch,
    });

    // console.log(investorOptions, "All Investors")
    // console.log(assignedInvestor, "Assigned Investor")

    // Filter out already assigned investors and inactive investors
    const filterOptions = investorOptions
        ?.filter(
            (investor) =>
                investor.isActive && // Check if the investor is active
                !assignedInvestor?.some((assigned) => assigned.investor._id === investor._id) // Exclude already assigned investors
        )
        .sort((a, b) => a.name.localeCompare(b.name)); // Sort alphabetically by name


    useEffect(() => {
        async function fetchUserData() {
            setIsFetching(true);
            const response = await axiosInstance.get(api + "getSingleInvestorUser/" + targetID);
            const { assignedTo } = response.data;
            setAssignedTo(assignedTo); // Pre-fill the form with the assigned investor
            setIsFetching(false);
        }

        fetchUserData();
    }, [axiosInstance, api, targetID]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        const formData = new FormData();
        formData.append("investor", assignedTo); // Assigned investor ID
        formData.append("investorUser", targetID); // Target investor user ID

        const { data } = await axiosInstance.post(
            MANAGE_INVESTOR_USERS_INVESTORS_API + "addInvestorToInvestorUser/",
            formData,
        );



        if (data.message === "Investor Already Added to InvestorUser") {
            setError("Investor Already Added to InvestorUser");
            setIsLoading(false);
        }

        if (data.message === "Investor assigned to InvestorUser") {
            setError(null);
            setAssignedTo("");
            setShowUpdateForm(false);
            triggerFetch();
            setIsLoading(false);
        }



    };

    if (isFetching) {
        return <div>Loading...</div>;
    }

    return (
        <Form onSubmit={handleSubmit}>
            <SelectInput
                label={"Assign To"}
                value={assignedTo}
                placeholder={"Select an Investor"}
                setState={setAssignedTo}
                required
            >
                <SelectOption optionValue={""} optionText={"Select Investor"} />
                {filterOptions?.length > 0 &&
                    filterOptions.map(({ _id, name }) => (
                        <SelectOption key={_id} optionValue={_id} optionText={name} />
                    ))}
                {filterOptions?.length === 0 &&
                    <SelectOption disabled={true} optionValue={""} optionText={"No Investors Available"} />
                }

                {/* {
                    filterOptions && filterOptions.length === 0 &&
                    <div className="text-center text-red-400 my-10">
                        No new investors available to assign.
                    </div>
                } */}
            </SelectInput>

            {error && <p className="error-text text-center my-4 text-red-400">{error}</p>}

            <FormSubmitButton
                text={isLoading ? "Updating..." : "Update Investment User"}
                disabled={isLoading}
            />
        </Form>
    );
}

export default AssignInvestorUser;
