export const CUSTOMERS_API = "customers/";
export const MANAGE_Employees_API = "manageEmployees/";
export const MANAGE_Investors_API = "manageInvestors/";
export const MANAGE_INVESTOR_USERS_API = "manageInvestorUser/";
export const MANAGE_INVESTOR_USERS_INVESTORS_API =
  "manageInvestorUsersInvestor/";

export const MANAGE_Company_API = "manageCompanies/";

export const MANAGE_TokenInvestors_API = "manageTokenInvestors/";

export const MANAGE_BANK_DETAILS_API = "manageInvestorBankDetails/";

export const MANAGE_Tokens_API = "manageTokens/";

export const MANAGE_TOKEN_IMAGES_API = "manageTokenImages/";

export const MANAGE_TOKEN_REQUESTS_API = "manageTokenRequest/";

export const MANAGE_INVESTMENT_DOCUMENTS_API = "manageInvestmentDocuments/";

export const MANAGE_ALLINVESTMENTS_API = "manageAllInvestments/";

export const MANAGE_STATISTICS_API = "manageStatistics/";
