import ForgotPassword from "./ForgotPassword/ForgotPassword"
import Login from "./Login/Login"
import RecoverPassword from "./RecoverPassword/RecoverPassword"
import Register from "./Register/Register"

const authRoutes = [
 
    {
        path: "login",
        element: <Login />
    },
    {
        path: "register/:token",
        element: <Register />
    },
    {
        path: "forgotPassword",
        element: <ForgotPassword />
    },
    {
        path: "recoverPassword/:token",
        element: <RecoverPassword />
    },
  
]

export default authRoutes