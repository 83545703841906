import { RiArchive2Line, RiFileList2Line, RiMailSendLine } from 'react-icons/ri';
import './CRUDButtons.css'
import { IconEye, IconEdit, IconTrash } from 'antopolis-react-icons';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

export function CRUDButton({ children, handleClick, deleteButton, tooltipId, tooltipContent }) {
    return (
        <>
            {tooltipId && (
                <Tooltip id={tooltipId} />
            )}
            <button
                data-tooltip-id={tooltipId}
                data-tooltip-content={tooltipContent}
                className={deleteButton ? 'trash' : ''}
                onClick={handleClick}
            >
                {children}
            </button>
        </>
    )
}

export function ViewButton({ setShowViewSection, setTargetID, targetID, setShowActionButton }) {
    return (
        <CRUDButton
            handleClick={() => {
                setShowViewSection(true)
                setTargetID(targetID)
                if (setShowActionButton) {
                    setShowActionButton(false)
                }
            }}
        >
            <IconEye />
        </CRUDButton>
    )
}

export function EditButton({ setShowUpdateForm, setTargetID, targetID, setShowActionButton, tooltipId, tooltipContent }) {
    return (
        <CRUDButton
            tooltipId={tooltipId}
            tooltipContent={tooltipContent}
            handleClick={() => {
                setShowUpdateForm(true)
                setTargetID(targetID)
                if (setShowActionButton) {
                    setShowActionButton(false)
                }
            }}
        >
            <IconEdit />
        </CRUDButton>
    )
}
export function ResendButton({ setShowResendSection, setTargetID, targetID, setShowActionButton }) {
    return (
        <CRUDButton
            handleClick={() => {
                setShowResendSection(true)
                setTargetID(targetID)
                if (setShowActionButton) {
                    setShowActionButton(false)
                }
            }}
        >
            <RiMailSendLine size={25} />
        </CRUDButton>
    )
}
export function ArchiveButton({ setShowArchiveForm, setTargetID, targetID, setShowActionButton, setActive, isActive }) {
    return (
        <CRUDButton
            handleClick={() => {
                setShowArchiveForm(true)
                setTargetID(targetID)
                setActive(isActive)
                if (setShowActionButton) {
                    setShowActionButton(false)
                }
            }}
        >
            <RiArchive2Line />
            {/* <IconEdit /> */}
        </CRUDButton>
    )
}


export function DeleteButton({ setShowDeleteSection, targetID, setTargetID, setShowActionButton, tooltipId, tooltipContent }) {
    return (
        <CRUDButton
            handleClick={() => {
                setShowDeleteSection(true)
                setTargetID(targetID)
                if (setShowActionButton) {
                    setShowActionButton(false)
                }
            }}
            deleteButton
        >
            <IconTrash />
        </CRUDButton>
    )
}


export function DocsButton({ setShowDocumentUploadForm, setTargetID, targetID, setShowActionButton }) {
    return (
        <CRUDButton
            handleClick={() => {
                setShowDocumentUploadForm(true);
                setTargetID(targetID);
                if (setShowActionButton) {
                    setShowActionButton(false);
                }
            }}
        >
            <RiFileList2Line />
        </CRUDButton>
    );
}
