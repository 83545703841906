import "./legend.css";

const Legend = () => {
  return (
    <div className="legend">
      <div className="legend-item">
        <div className="legend-box investment"></div>
        <span className="legend-text">Investment</span>
      </div>
      <div className="legend-item">
        <div className="legend-box maturity"></div>
        <span className="legend-text">Projected Profit</span>
      </div>
    </div>
  );
};

export default Legend;
