import Switch from "react-switch";
import "./SwitchInput.css";

function SwitchInput({ label, toggleSwitch, checked }) {
  return (
    <div>
      <label className="input_field_label">{label}</label>
      <Switch
        className="ecommerce-switch"
        onChange={toggleSwitch}
        checked={checked}
        onColor="#f1652fcc"
      />
    </div>
  );
}

export default SwitchInput;
