import { useResetPassword } from "antopolis-react-utils/hooks/auth"
import { useNavigate } from "react-router-dom"

import { RESET_PASSWORD_API } from "../../../../../Utilities/APIs/AuthAPIs/AuthAPIs"
import PasswordInput from "../../../../Partials/Forms/FormInputs/PasswordInput/PasswordInput"
import useAxiosAuthInstance from "../../../../../Utilities/Hooks/axiosAuthInstance"
import Form from "../../../../Partials/Forms/Form"
import FormSubmitButton from "../../../../Partials/Forms/FormSubmitButton/FormSubmitButton"
import { useContext } from "react"
import { AuthContext } from "../../../../../Contexts/GlobalContexts/AuthContext"


function RecoverPasswordForm({token}) {

    const navigate = useNavigate()
    const axiosAuthInstance = useAxiosAuthInstance()
    const { setError } = useContext(AuthContext)
  
    const {
      newPass,
      setNewPass,
      newPass2,
      setNewPass2,
      handleSubmit,
    } = useResetPassword({
      axiosInstance: axiosAuthInstance,
      api: RESET_PASSWORD_API,
      token,
      navigate,
      route: '/auth/login',
      setErrorMessage: setError
    })

    return (
        <Form
            onSubmit={handleSubmit}
        >
            <PasswordInput
                label='New Password'
                value={newPass}
                placeholder='Enter New Password'
                setState={setNewPass}
            />
            <PasswordInput
                label='Confirm Password'
                value={newPass2}
                placeholder='Re-enter Password'
                setState={setNewPass2}
            />

            <FormSubmitButton text='Recover Password' />

        </Form>
    )
}

export default RecoverPasswordForm