import { useState } from "react";
import { useAxiosInstance } from "../../../../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../../../../Partials/Forms/Form";
import EmailInput from "../../../../../Partials/Forms/FormInputs/EmailInput/EmailInput";
import ShortTextInput from "../../../../../Partials/Forms/FormInputs/ShortTextInput/ShortTextInput";
import FormSubmitButton from "../../../../../Partials/Forms/FormSubmitButton/FormSubmitButton";
import NumberInput from "../../../../../Partials/Forms/FormInputs/NumberInput/NumberInput";
import ImageInput from "../../../../../Partials/Forms/FormInputs/ImageInput/ImageInput";

function CreateInvestor({ employee, api, setShowCreateForm, triggerFetch }) {
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [image, setImage] = useState(null);
  const [startingProfit, setStartingProfit] = useState(0);
  const [company, setCompany] = useState("");
  const [designation, setDesignation] = useState("");
  const [emailError, setEmailError] = useState("");
  const [nameError, setNameError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const axiosInstance = useAxiosInstance();

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  async function handleSubmit(e) {
    e.preventDefault();
    setIsLoading(true);
    setHasError(false);
    let isValid = true;

    // Name validation: only alphabets allowed
    // const nameRegex = /^[A-Za-z\s]+$/;

    // else if (!nameRegex.test(name)) {
    //   setNameError("Only alphabets are allowed for the name.");
    //   isValid = false;
    // }

    if (!name) {
      setNameError("Name is required.");
      isValid = false;
    } else {
      setNameError("");
    }


    // Phone validation: must be between 7 and 15 digits
    // if (!phone) {
    //   setPhoneError("Phone number is required.");
    //   isValid = false;
    // } else if (phone.length < 7 || phone.length > 15) {
    //   setPhoneError("Phone number must be between 7 and 15 digits.");
    //   isValid = false;
    // } else {
    //   setPhoneError("");
    // }

    if (!isValid) {
      setIsLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append("email", email);
    formData.append("name", name);
    formData.append("startingProfit", startingProfit || 0);
    formData.append("company", company);
    formData.append("designation", designation);
    formData.append("phone", phone);
    if (image) {
      formData.append("image", image);
    }

    try {
      const response = await axiosInstance.post(api + "createInvestor", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response) {
        setEmail("");
        setName("");
        setPhone("");
        setCompany("");
        setDesignation("");
        setStartingProfit(null);
        setImage(null);
        setShowCreateForm(false);
        triggerFetch();
      }
    } catch (error) {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  }


  return (
    <Form onSubmit={handleSubmit}>
      <ShortTextInput
        label={"Name"}
        value={name}
        placeholder={"Please Enter a Name"}
        setState={setName}
        required
      />
      {nameError && <p className="error-text text-red-400">{nameError}</p>}


      <NumberInput
        label={"Phone"}
        value={phone}
        placeholder={"Please Enter Phone Number"}
        setState={setPhone}
        required
        min={1000000}
        max={999999999999999}
      />
      {phoneError && <p className="error-text text-red-400">{phoneError}</p>}


      <EmailInput
        label={"Email"}
        value={email}
        placeholder={"Please Enter an Email"}
        setState={setEmail}
      />
      {emailError && <p className="error-text text-red-400">{emailError}</p>}

      <ShortTextInput
        label={"Company"}
        value={company}
        placeholder={"Please Enter Company name"}
        setState={setCompany}
      />

      <ShortTextInput
        label={"Designation"}
        value={designation}
        placeholder={"Please Enter Designation"}
        setState={setDesignation}
      />

      <NumberInput
        label={"Starting Profit (Taka)"}
        value={startingProfit}
        placeholder={"Add Starting Profit if any"}
        setState={setStartingProfit}
      />

      <ImageInput
        allowCreateImage
        label={"Profile Image"}
        state={image}
        setState={setImage}
        fieldId={"Investor Image"}
      >
        Select Profile Picture
      </ImageInput>

      {hasError && <p className="error-text text-red-400">Failed to create investor. Please try again.</p>}

      <FormSubmitButton text={isLoading ? "Loading..." : "Create Investor"} disabled={isLoading} />
    </Form>
  );
}

export default CreateInvestor;
