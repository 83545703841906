import { useGetInfo } from "antopolis-react-utils/hooks";
import { useAxiosInstance } from "../../../../../../Utilities/Hooks/useAxiosInstance";
import { Image } from "../../../../../Partials/Elements/Image/Image";
import { HERO_SLIDERS_API } from "../../../../../../Utilities/APIs/WebsiteContentsAPIs/WebsiteContentsAPIs";

function ViewHeroSlider({
    targetID,
}) {

    const axiosInstance = useAxiosInstance();

    const { info: heroSlider } = useGetInfo({
        axiosInstance: axiosInstance,
        api:  HERO_SLIDERS_API + 'singleHeroSlider/' + targetID,
    })

    return (

        <div className="crud_view_content">

            {heroSlider &&
                <>
                    {/* <h1>Title</h1>
                    <p>HeroSlider</p> */}

                    <h1>Image</h1>
                    <Image
                        imgLink={heroSlider.image}
                        imgAlt={'info'}
                        className={''}
                    />

                    <h1>Text</h1>
                    <p>{heroSlider.text}</p>

                    <h1>Precedence</h1>
                    <p>{heroSlider.precedence}</p>


                </>
            }
        </div>
    )
}

export default ViewHeroSlider