import { useContext, useMemo, useState } from "react";
import { BsBriefcaseFill } from "react-icons/bs";
import { AuthContext } from "../../../../../Contexts/GlobalContexts/AuthContext";
import { useAxiosInstance } from "../../../../../Utilities/Hooks/useAxiosInstance";
import { useGetInfo } from "antopolis-react-utils/hooks";
import { useNavigate } from "react-router-dom";
import BackButton from "../../../../Partials/Elements/BackButton/BackButton";
import { CreateButton } from "../../../../Partials/Layout/CRUD/CreateButton/CreateButton";
import { CRUDTable } from "../../../../Partials/Layout/CRUD/CRUDTable/CRUDTable";
import { CRUDTableHeader } from "../../../../Partials/Layout/CRUD/CRUDTableHeader/CRUDTableHeader";
import { CRUDth } from "../../../../Partials/Layout/CRUD/CRUDth/CRUDth";
import { ShortTextCell } from "../../../../Partials/Layout/CRUD/ShortTextCell/ShortTextCell";
import { CRUDButton, EditButton, ResendButton } from "../../../../Partials/Layout/CRUD/CRUDButtons/CRUDButtons";
import CreateInvestorUser from "./CreateInvestorUser";
import { MANAGE_INVESTOR_USERS_API } from "../../../../../Utilities/APIs/APIs";
import { Modal } from "../../../../Partials/Elements/Modal/Modal";
import SelectInput from "../../../../Partials/Forms/FormInputs/SelectInput/SelectInput";
import SelectOption from "../../../../Partials/Forms/FormInputs/SelectInput/SelectOption/SelectOption";
import UpdateInvestorUser from "./UpdateInvestorUser";
import { BiTrash } from "react-icons/bi";
import ArchiveInvestorUser from "./ArchiveInvestorUser";
import ResendInvite from "./InvestorUserInvite/ResendInvite";
import DeleteInvite from "./InvestorUserInvite/DeleteInvite";
import { TbTrashOff } from "react-icons/tb";
import UnArchiveInvestorUser from "./UnArchiveInvestorUser";

function InvestorUsers({ api }) {
    const [toggleFetch, setToggleFetch] = useState(false);
    const [showCreateForm, setShowCreateForm] = useState(false);
    const [showUpdateForm, setShowUpdateForm] = useState(false);
    const [showArchiveSection, setShowArchiveSection] = useState(false);
    const [showResendSection, setShowResendSection] = useState(false);
    const [showDeleteInviteSection, setShowDeleteInviteSection] = useState(false);
    const [showUnArchiveSection, setShowUnArchiveSection] = useState(false);

    const [filter, setFilter] = useState("active");
    const [targetID, setTargetID] = useState(null);

    const { employee } = useContext(AuthContext);
    const axiosInstance = useAxiosInstance();
    const { info: investorUsers } = useGetInfo({
        axiosInstance: axiosInstance,
        api: api + "getAllInvestorUsers",
        toggleFetch,
    });

    const navigate = useNavigate();

    function triggerFetch() {
        setToggleFetch((prevState) => !prevState);
    }

    const handleFilterChange = (value) => {
        setFilter(value);
    };

    const filteredInvestorUsers = useMemo(() => {
        if (!investorUsers) return [];
        return investorUsers
            .filter((user) => {
                if (filter === "active") return user.isActive && user.isEmailVerified;
                if (filter === "invited") return !user.isEmailVerified && user.isActive;
                if (filter === "archived") return !user.isActive;
                return true; // 'all' case, show all users
            })
            .sort((a, b) => (a.name || "").localeCompare(b.name || ""));
    }, [investorUsers, filter]);

    return (
        <div className="screen_wrapper">
            <div className="screen_header">
                <div style={{ display: "flex", alignItems: "center", gap: "20px", justifyContent: "center" }}>
                    <BackButton />
                    <h1 className="screen_heading">{`${filter.toUpperCase()} Investment Users (${filteredInvestorUsers.length} in total)`}</h1>
                </div>

                <div>
                    <div style={{ display: "flex", gap: "20px" }}>
                        <SelectInput
                            label={""}
                            value={filter}
                            placeholder={"Please Select Filter"}
                            setState={handleFilterChange}
                        >
                            <SelectOption optionValue={"active"} optionText={"Active"} />
                            <SelectOption optionValue={"invited"} optionText={"Invited"} />
                            <SelectOption optionValue={"archived"} optionText={"Archived"} />
                        </SelectInput>
                    </div>
                </div>

                <div style={{ display: "flex", gap: "20px" }}>
                    <CreateButton
                        screenTopicSingular={"Investment User"}
                        setShowCreateForm={setShowCreateForm}
                    />
                </div>
            </div>

            <CRUDTable>
                <CRUDTableHeader>
                    <CRUDth align={"left"} th="Name" />
                    <CRUDth th="Email Address" />
                    <CRUDth th="Actions" />
                </CRUDTableHeader>
                <tbody>
                    {filteredInvestorUsers.map((investorUser) => (
                        <tr key={investorUser._id}>
                            <td>
                                <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                    <img
                                        style={{ height: "30px", width: "30px", borderRadius: "50%" }}
                                        src={
                                            investorUser.image
                                                ? process.env.REACT_APP_SPACES_URL + investorUser.image
                                                : `https://ui-avatars.com/api/?name=${investorUser.name}&background=random&length=1`
                                        }
                                        alt=""
                                    />
                                    <p>{investorUser.name}</p>
                                </div>
                            </td>
                            <ShortTextCell text={investorUser.email} />
                            <td className="action_buttons_wrapper">
                                <div className="action_buttons wc_investor_action_buttons">
                                    {
                                        <EditButton
                                            setShowUpdateForm={setShowUpdateForm}
                                            targetID={investorUser._id}
                                            setTargetID={setTargetID}
                                        />
                                    }
                                    {
                                        investorUser.isActive && investorUser.isEmailVerified && <CRUDButton
                                            handleClick={() => navigate(`/main/investorUsersInvestors/${investorUser._id}`)}
                                            tooltipId="investmentUser-manage-btn"
                                            tooltipContent="Manage Investor"
                                        >
                                            <BsBriefcaseFill size={25} />

                                        </CRUDButton>
                                    }
                                    {
                                        investorUser.isActive && investorUser.isEmailVerified && <CRUDButton
                                            handleClick={() => {
                                                setShowArchiveSection(true);
                                                setTargetID(investorUser._id);
                                            }}
                                            tooltipId="investmentUser-archive-btn"
                                            tooltipContent="Archive"
                                        >
                                            <div className="text-red-500 flex items-center justify-center">
                                                <BiTrash size={25} />
                                            </div>

                                        </CRUDButton>
                                    }
                                    {
                                        investorUser.isActive && !investorUser.isEmailVerified && <ResendButton
                                            setShowResendSection={setShowResendSection}
                                            targetID={investorUser._id}
                                            setTargetID={setTargetID}
                                        />

                                    }
                                    {
                                        investorUser.isActive && !investorUser.isEmailVerified && <CRUDButton
                                            handleClick={() => {
                                                setShowDeleteInviteSection(true);
                                                setTargetID(investorUser._id);
                                            }}
                                        >
                                            <div className="text-red-500 flex items-center justify-center">
                                                <BiTrash size={25} />
                                            </div>

                                        </CRUDButton>

                                    }

                                    {
                                        !investorUser.isActive && investorUser.isEmailVerified && <CRUDButton
                                            handleClick={() => {
                                                setShowUnArchiveSection(true);
                                                setTargetID(investorUser._id);
                                            }}
                                            tooltipId="single-investor-User--btn"
                                            tooltipContent="Active User"
                                        >
                                            <div className=" flex items-center justify-center">
                                                <TbTrashOff size={25} />
                                            </div>

                                        </CRUDButton>
                                    }
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </CRUDTable>

            {showCreateForm && (
                <Modal
                    extraClass={"small"}
                    modalHeading={`Create Investment User`}
                    setShowModalContent={setShowCreateForm}
                >
                    <CreateInvestorUser
                        api={MANAGE_INVESTOR_USERS_API}
                        employee={employee}
                        setShowCreateForm={setShowCreateForm}
                        triggerFetch={triggerFetch}
                    />
                </Modal>
            )}
            {showUpdateForm && (
                <Modal
                    extraClass={"small"}
                    modalHeading={`Update Investor User`}
                    setShowModalContent={setShowUpdateForm}
                >
                    <UpdateInvestorUser
                        api={MANAGE_INVESTOR_USERS_API}
                        employee={employee}
                        setShowUpdateForm={setShowUpdateForm}
                        triggerFetch={triggerFetch}
                        targetID={targetID}
                    />
                </Modal>
            )}
            {showArchiveSection && (
                <Modal
                    extraClass={"small"}
                    modalHeading={`Archive Investment User`}
                    setShowModalContent={setShowArchiveSection}
                >
                    <ArchiveInvestorUser
                        api={MANAGE_INVESTOR_USERS_API}
                        employee={employee}
                        setShowArchiveSection={setShowArchiveSection}
                        targetID={targetID}
                        triggerFetch={triggerFetch}
                    />
                </Modal>
            )}

            {showUnArchiveSection && (
                <Modal
                    extraClass={"small"}
                    modalHeading={`Archive Investment User`}
                    setShowModalContent={setShowUnArchiveSection}
                >
                    <UnArchiveInvestorUser
                        api={MANAGE_INVESTOR_USERS_API}
                        employee={employee}
                        setShowUnArchiveSection={setShowUnArchiveSection}
                        targetID={targetID}
                        triggerFetch={triggerFetch}
                    />
                </Modal>
            )}



            {
                showResendSection &&
                <Modal
                    extraClass={"small"}
                    modalHeading={`Resend Invite`}
                    setShowModalContent={setShowResendSection}
                >
                    <ResendInvite
                        api={MANAGE_INVESTOR_USERS_API}
                        employee={employee}
                        setShowResendSection={setShowResendSection}
                        targetID={targetID}
                    />
                </Modal>
            }

            {
                showDeleteInviteSection &&
                <Modal
                    extraClass={"small"}
                    modalHeading={`Delete Invite`}
                    setShowModalContent={setShowDeleteInviteSection}
                >
                    <DeleteInvite
                        api={MANAGE_INVESTOR_USERS_API}
                        employee={employee}
                        setShowDeleteInviteSection={setShowDeleteInviteSection}
                        targetID={targetID}
                        triggerFetch={triggerFetch}
                    />
                </Modal>
            }
        </div>
    );
}

export default InvestorUsers;
