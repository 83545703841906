
// const exportToCSV = (investors) => {
//   const headers = ["Name", "Email Address", "Status"];
//   const rows = investors.map(investor => [
//     investor.name,
//     investor.email,
//     investor.isActive ? "Active" : "Inactive"
//   ]);

import { useContext, useMemo, useState } from "react";
import { AuthContext } from "../../../../../../Contexts/GlobalContexts/AuthContext";
import { useAxiosInstance } from "../../../../../../Utilities/Hooks/useAxiosInstance";
import { useGetInfo } from "antopolis-react-utils/hooks";
import BackButton from "../../../../../Partials/Elements/BackButton/BackButton";
import { CreateButton } from "../../../../../Partials/Layout/CRUD/CreateButton/CreateButton";
import { CRUDTable } from "../../../../../Partials/Layout/CRUD/CRUDTable/CRUDTable";
import { CRUDTableHeader } from "../../../../../Partials/Layout/CRUD/CRUDTableHeader/CRUDTableHeader";
import { CRUDth } from "../../../../../Partials/Layout/CRUD/CRUDth/CRUDth";
import { ShortTextCell } from "../../../../../Partials/Layout/CRUD/ShortTextCell/ShortTextCell";
import { CRUDButton, DeleteButton, EditButton } from "../../../../../Partials/Layout/CRUD/CRUDButtons/CRUDButtons";
import { BsFileEarmarkBarGraph } from "react-icons/bs";
import { Modal } from "../../../../../Partials/Elements/Modal/Modal";
import UnAssignInvestorUser from "./UnAssignInvestorUser";
import { MANAGE_INVESTOR_USERS_INVESTORS_API } from "../../../../../../Utilities/APIs/APIs";
import { useNavigate } from "react-router-dom";

//   const csvContent =
//     "data:text/csv;charset=utf-8," +
//     [headers.join(","), ...rows.map(row => row.join(","))].join("\n");

//   const encodedUri = encodeURI(csvContent);
//   const link = document.createElement("a");
//   link.setAttribute("href", encodedUri);
//   link.setAttribute("download", "investors.csv");
//   document.body.appendChild(link);

//   link.click();
//   document.body.removeChild(link);
// };

function InvestorUsersInvestors({ api }) {
    const [toggleFetch, setToggleFetch] = useState(false);
    const [showUpdateForm, setShowUpdateForm] = useState(false);
    const [showDeleteSection, setShowDeleteSection] = useState(false);
    const [targetID, setTargetID] = useState(null);
    const [showCreateForm, setShowCreateForm] = useState(false);
    const { employee } = useContext(AuthContext);
    const axiosInstance = useAxiosInstance();
    const { info: investorUsersInvestors } = useGetInfo({
        axiosInstance: axiosInstance,
        api: api + "getAllInvestorUsersInvestors",
        toggleFetch,
    });





    const navigate = useNavigate();

    // const handleRowClick = (e, investor) => {
    //     const isButtonClick = e.target.closest(".wc_investor_action_buttons");
    //     if (!isButtonClick) {
    //         navigate("/main/investments/" + investor._id);
    //     }
    // };

    function triggerFetch() {
        setToggleFetch((prevState) => !prevState);
    }

    const sortedInvestorUsersInvestors = useMemo(() => {
        if (!investorUsersInvestors) return [];
        return investorUsersInvestors
            .slice()
            .sort((a, b) => (a.name || "").localeCompare(b.name || ""));
    }, [investorUsersInvestors]);

    return (
        <div className="screen_wrapper">
            <div className="screen_header">
                <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                    <BackButton />
                    <h1 className="screen_heading">{`Investment Users Investors (${investorUsersInvestors?.length || 0} in total)`}</h1>
                </div>
                <div style={{ display: "flex", gap: "20px" }}>
                    <CreateButton
                        screenTopicSingular={"Invest User"}
                        setShowCreateForm={setShowCreateForm}
                    />
                    {/* <CRUDButton handleClick={() => exportToCSV(sortedInvestors)}>
            <p style={{ fontSize: "16px", color: "#FFE09D", textAlign: "center" }}>Export To CSV</p>
          </CRUDButton> */}
                </div>
            </div>
            <CRUDTable>
                <CRUDTableHeader>
                    <CRUDth th="Investor Name" />
                    <CRUDth th="Investment User" />
                    <CRUDth th="Actions" />
                </CRUDTableHeader>
                <tbody>
                    {sortedInvestorUsersInvestors &&
                        sortedInvestorUsersInvestors.map((investorUsersInvestor) => (
                            <tr
                                key={investorUsersInvestor._id}
                                className="investor_link"
                            // onClick={(e) => handleRowClick(e, investorUsersInvestor)}
                            >
                                <td>
                                    <div style={{ display: "flex", alignItems: "center", gap: "5px", justifyContent: "center" }}>
                                        <img
                                            style={{ height: "30px", width: "30px", borderRadius: "50%" }}
                                            src={process.env.REACT_APP_SPACES_URL + investorUsersInvestor?.investor?.image}
                                            alt=""
                                        />
                                        <p>{investorUsersInvestor.investor.name}</p>
                                    </div>
                                </td>
                                <ShortTextCell text={investorUsersInvestor?.investorUser?.name} />
                                {/* <ShortTextCell
                                    text={investorUsersInvestor.isActive ? "Active" : "Inactive"}
                                /> */}
                                <td className="action_buttons_wrapper">
                                    <div className="action_buttons wc_investor_action_buttons">
                                        <EditButton
                                            setShowUpdateForm={setShowUpdateForm}
                                            targetID={investorUsersInvestor._id}
                                            setTargetID={setTargetID}
                                        />

                                        <CRUDButton handleClick={() => navigate(`/main/investmentsGraph/${investorUsersInvestor._id}`)}>
                                            <BsFileEarmarkBarGraph size={25} />
                                        </CRUDButton>

                                        {/* <DeleteButton
                                            setShowDeleteSection={setShowDeleteSection}
                                            targetID={investorUsersInvestor._id}
                                            setTargetID={setTargetID}
                                        /> */}

                                    </div>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </CRUDTable>
            {/* 

            {showCreateForm && (
                <Modal
                    extraClass={"small"}
                    modalHeading={`Create Investor User`}
                    setShowModalContent={setShowCreateForm}
                >
                    <CreateInvestorUser
                        api={MANAGE_INVESTOR_USERS_API}
                        employee={employee}
                        setShowCreateForm={setShowCreateForm}
                        triggerFetch={triggerFetch}
                    />
                </Modal>
            )}
      {showUpdateForm && (
        <Modal
          extraClass={"small"}
          modalHeading={`Update Investor`}
          setShowModalContent={setShowUpdateForm}
        >
          <UpdateInvestor
            api={MANAGE_Investors_API}
            employee={employee}
            setShowUpdateForm={setShowUpdateForm}
            targetID={targetID}
            triggerFetch={triggerFetch}
          />
        </Modal>
      )} */}


            {showDeleteSection && (
                <Modal
                    extraClass={"small"}
                    modalHeading={`Delete Investor`}
                    setShowModalContent={setShowDeleteSection}
                >
                    <UnAssignInvestorUser
                        api={MANAGE_INVESTOR_USERS_INVESTORS_API}
                        employee={employee}
                        setShowDeleteSection={setShowDeleteSection}
                        targetID={targetID}
                        triggerFetch={triggerFetch}
                    />
                </Modal>
            )}
        </div>
    );
}

export default InvestorUsersInvestors;
