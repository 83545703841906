import { useContext } from "react"
import { IconClose } from "antopolis-react-icons"
import SidebarLogo from "./SidebarLogo/SidebarLogo"
import SidebarContext from "../../SidebarContext"
import { useUseOrientation } from "../../../../../../Utilities/Hooks/useUseOrientation"

import './SidebarTop.css'

function SidebarTop({ expandSidebar }) {
    const { setShowSidebar } = useContext(SidebarContext)

    const { portrait } = useUseOrientation()

    return (
        <div className={`sidebar_top ${expandSidebar ? 'expanded_logo' : ''}`}>
            <SidebarLogo expandSidebar={expandSidebar} />
            {
                portrait &&
                <button
                    className="btn_close"
                    onClick={() => setShowSidebar(false)}
                >
                    <IconClose />
                </button>
            }
        </div>
    )
}

export default SidebarTop