

import React, { useState, useEffect } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

function getYAxisTicks(maxValue) {
  const numOfTicks = 5;
  let interval;
  const ticks = [];

  const rawInterval = maxValue / numOfTicks;

  if (rawInterval >= 10000000) {
    interval = Math.ceil(rawInterval / 10000000) * 10000000;
  } else {
    interval = Math.ceil(rawInterval / 100000) * 100000;
  }

  for (let i = 0; i <= maxValue; i += interval) {
    ticks.push(i);
  }

  if (ticks[ticks.length - 1] !== maxValue) {
    ticks.push(maxValue);
  }

  return ticks;
}

function formatScaleMark(value) {
  if (value >= 10000000) {
    return `${(value / 10000000).toFixed(2)} Cr`;
  } else if (value >= 100000) {
    return `${(value / 100000).toFixed(2)} L`;
  } else {
    return `${(value / 1000).toFixed(2)} K`;
  }
}

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-[#2c2c2c] p-2 rounded-md shadow-lg">
        <p className="text-white text-lg font-semibold">
          {formatScaleMark(payload[0].value, payload[0].payload.investment)}
        </p>
        <p className="text-gray-400 text-sm">{label}</p>
      </div>
    );
  }
  return null;
};

const InvestmentChart = ({ chartData, setChartData, investmentData }) => {
  const [processedData, setProcessedData] = useState([]);

  useEffect(() => {
    if (investmentData && investmentData.length > 0) {
      const formattedData = investmentData.map((item) => ({
        ...item,
        month: item.month.slice(0, 3),
      }));
      setProcessedData(formattedData);
    } else {
      setProcessedData([]);
    }
    console.log("Selected Investment Type:", chartData);
    console.log("Data being processed:", investmentData);
  }, [investmentData, chartData]);

  const maxInvestment =
    Math.max(...processedData.map((item) => item.investment)) || 0;
  const yAxisTicks = getYAxisTicks(maxInvestment);

  return (
    <div className="p-8 rounded-lg">
      <div className="flex justify-between items-center mb-4">
        <div>
          <h3 className="text-white text-3xl font-medium">Total Investments</h3>
          <div className="text-5xl text-white font-medium mb-2 mt-6">
            {formatScaleMark(
              processedData[processedData.length - 1]?.investment || 0,
              maxInvestment
            )}
          </div>
        </div>
        <div className="flex items-center">
          <div className="w-[8px] h-[8px] bg-yellow-400 me-3 rounded-full"></div>
          <span className="text-xl text-[#6b6969] font-medium">
            Investment Rate
          </span>
          <div
            className="relative ml-4"
            onClick={() => document.getElementById("chartSelect").focus()}
          >
            <select
              id="chartSelect"
              value={chartData}
              onChange={(event) => setChartData(event.target.value)}
              className="bg-transparent text-white text-2xl border rounded p-2 pr-8 outline-none"
            >
              <option className="bg-gray-600" value="companies">
                Companies
              </option>
              <option className="bg-gray-600" value="nano-assets">
                Nano Assets
              </option>
            </select>

            <svg
              className="absolute right-2 top-1/2 transform -translate-y-1/2 pointer-events-none"
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="10"
              fill="none"
              viewBox="0 0 16 16"
            >
              <path
                fill="#6b6969"
                fillRule="evenodd"
                d="M1.5 6.5a.5.5 0 0 1 .75 0L8 10.5l5.75-4a.5.5 0 1 1 .5.866l-6 4.5a.5.5 0 0 1-.5 0l-6-4.5a.5.5 0 0 1 0-.866z"
              />
            </svg>
          </div>
        </div>
      </div>

      <ResponsiveContainer width="100%" height={350}>
        <AreaChart
          data={processedData}
          margin={{ top: 20, right: 20, left: 0, bottom: 0 }} // Adjust margins to remove extra space
        >
          <defs>
            <linearGradient id="colorLine" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#F9C51C" stopOpacity={0.5} />
              <stop offset="100%" stopColor="#F9C51C" stopOpacity={0.1} />
            </linearGradient>
          </defs>
          <CartesianGrid stroke="rgba(255, 255, 255, 0.1)" vertical={false} />
          <XAxis
            dataKey="month"
            stroke="#888"
            tick={{ fontSize: 12 }} // Match X-axis font size with Y-axis
          />
          <YAxis
            stroke="#888"
            tickFormatter={(tick) => formatScaleMark(tick, maxInvestment)}
            domain={[0, Math.max(...yAxisTicks)]}
            ticks={yAxisTicks}
            tick={{ fontSize: 12 }} // Ensure Y-axis has the same font size
            tickMargin={10} // Add some gap between Y-axis line and the tickers
            axisLine={false}
            tickLine={false}
            width={70} // Adjust Y-axis width
          />
          <Tooltip content={<CustomTooltip />} />
          <Area
            type="monotone"
            dataKey="investment"
            stroke="#F9C51C"
            strokeWidth={2} // Make the line thinner
            fillOpacity={1}
            fill="url(#colorLine)" // Gradient for the area
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default InvestmentChart;
