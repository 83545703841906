import { useState, useMemo } from "react";
import { IconThreeDots } from "antopolis-react-icons";
import { useGetInfo } from "antopolis-react-utils/hooks";
import ViewHeroSlider from "./ViewHeroSlider";
import CreateHeroSlider from "./CreateHeroSlider";
import UpdateHeroSlider from "./UpdateHeroSlider";
import { useUseOrientation } from "../../../../../../Utilities/Hooks/useUseOrientation";
import { useAxiosInstance } from "../../../../../../Utilities/Hooks/useAxiosInstance";
import { CreateButton } from "../../../../../Partials/Layout/CRUD/CreateButton/CreateButton";
import { CRUDTable } from "../../../../../Partials/Layout/CRUD/CRUDTable/CRUDTable";
import { CRUDTableHeader } from "../../../../../Partials/Layout/CRUD/CRUDTableHeader/CRUDTableHeader";
import { CRUDth } from "../../../../../Partials/Layout/CRUD/CRUDth/CRUDth";
import {
  CRUDButton,
  EditButton,
  ViewButton,
} from "../../../../../Partials/Layout/CRUD/CRUDButtons/CRUDButtons";
import { Modal } from "../../../../../Partials/Elements/Modal/Modal";
import ArchiveHeroSlider from "./ArchiveHeroSlider";
import UnArchiveHeroSlider from "./UnArchiveHeroSlider";
import { TbTrashOff } from "react-icons/tb";
import { BiTrash } from "react-icons/bi";
import SelectInput from "../../../../../Partials/Forms/FormInputs/SelectInput/SelectInput";
import SelectOption from "../../../../../Partials/Forms/FormInputs/SelectInput/SelectOption/SelectOption";
import BackButton from "../../../../../Partials/Elements/BackButton/BackButton";

function HeroSlider({ api }) {
  const [showActionButton, setShowActionButton] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showArchiveSection, setShowArchiveSection] = useState(false);
  const [showUnArchiveSection, setShowUnArchiveSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);
  const [filter, setFilter] = useState("active");
  const [targetID, setTargetID] = useState(null);

  const { portrait } = useUseOrientation();
  const axiosInstance = useAxiosInstance();

  const { info: heroSliders } = useGetInfo({
    axiosInstance: axiosInstance,
    api: api + "getAllHeroSliders/",
    toggleFetch,
  });

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }

  // Filter hero sliders based on active/archived status
  const filteredHeroSliders = useMemo(() => {
    if (!heroSliders) return [];
    return heroSliders.filter((slider) =>
      filter === "active" ? slider.isActive : !slider.isActive
    );
  }, [heroSliders, filter]);

  return (
    <div className="screen_wrapper">
      <div className="screen_header">
        <h1 className="screen_heading flex gap-5">
          <BackButton />
          Hero Sliders ({filteredHeroSliders?.length} in total)
        </h1>
        <SelectInput
          label={""}
          value={filter}
          placeholder={"Please Select Filter"}
          setState={setFilter}
        >
          <SelectOption optionValue={"active"} optionText={"Active"} />
          <SelectOption optionValue={"archived"} optionText={"Archived"} />
        </SelectInput>
        <CreateButton
          screenTopicSingular={"Hero Slider"}
          setShowCreateForm={setShowCreateForm}
        />
      </div>
      <CRUDTable>
        <CRUDTableHeader>
          <CRUDth align={"left"} th="Image" />
          <CRUDth align={"left"} th="Precedence" />
          <CRUDth th="Actions" />
        </CRUDTableHeader>
        <tbody>
          {filteredHeroSliders.map((heroSlider) => (
            <tr key={heroSlider._id}>
              <td>
                <img
                  src={process.env.REACT_APP_SPACES_URL + heroSlider?.image}
                  alt="Slider"
                />
              </td>
              <td className="text-center">{heroSlider?.precedence}</td>
              <td>
                <div className="action_buttons_wrapper">
                  <button
                    className="three_dots"
                    onClick={() => {
                      setShowActionButton(true);
                      setTargetID(heroSlider._id);
                    }}
                  >
                    <IconThreeDots />
                  </button>
                  {!portrait && (
                    <div className="action_buttons">
                      <ViewButton
                        setShowViewSection={setShowViewSection}
                        targetID={heroSlider._id}
                        setTargetID={setTargetID}
                      />
                      <EditButton
                        setShowUpdateForm={setShowUpdateForm}
                        targetID={heroSlider._id}
                        setTargetID={setTargetID}
                      />
                      {heroSlider.isActive ? (
                        <CRUDButton
                          handleClick={() => {
                            setShowArchiveSection(true);
                            setTargetID(heroSlider._id);
                          }}
                          tooltipId="heroSlider-activate-btn"
                          tooltipContent="Archive"
                        >
                          <div className="flex items-center justify-center">
                            <BiTrash size={25} />
                          </div>
                        </CRUDButton>
                      ) : (
                        <CRUDButton
                          handleClick={() => {
                            setShowUnArchiveSection(true);
                            setTargetID(heroSlider._id);
                          }}
                          tooltipId="heroSlider-activate-btn"
                          tooltipContent="Active"
                        >
                          <div className="flex items-center justify-center">
                            <TbTrashOff size={25} />
                          </div>
                        </CRUDButton>
                      )}
                    </div>
                  )}
                  {portrait &&
                    showActionButton &&
                    targetID === heroSlider._id && (
                      <div className="action_buttons">
                        <ViewButton
                          setShowViewSection={setShowViewSection}
                          targetID={heroSlider._id}
                          setTargetID={setTargetID}
                        />
                        <EditButton
                          setShowUpdateForm={setShowUpdateForm}
                          targetID={heroSlider._id}
                          setTargetID={setTargetID}
                          setShowActionButton={setShowActionButton}
                        />
                      </div>
                    )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </CRUDTable>

      {showCreateForm && (
        <Modal
          extraClass={"small"}
          modalHeading={`Create Hero Slider`}
          setShowModalContent={setShowCreateForm}
        >
          <CreateHeroSlider
            setShowCreateForm={setShowCreateForm}
            triggerFetch={triggerFetch}
            api={api + "addHeroSlider"}
          />
        </Modal>
      )}
      {showUpdateForm && (
        <Modal
          extraClass={"small"}
          modalHeading={`Update Hero Slider`}
          setShowModalContent={setShowUpdateForm}
        >
          <UpdateHeroSlider
            setShowUpdateForm={setShowUpdateForm}
            targetID={targetID}
            triggerFetch={triggerFetch}
            api={api}
          />
        </Modal>
      )}
      {showViewSection && (
        <Modal
          extraClass={"medium"}
          modalHeading={`View Hero Slider`}
          setShowModalContent={setShowViewSection}
        >
          <ViewHeroSlider targetID={targetID} api={api} />
        </Modal>
      )}
      {showArchiveSection && (
        <Modal
          extraClass={"small"}
          modalHeading={`Archive Hero Slider`}
          setShowModalContent={setShowArchiveSection}
        >
          <ArchiveHeroSlider
            setShowArchiveSection={setShowArchiveSection}
            targetID={targetID}
            triggerFetch={triggerFetch}
            api={api}
          />
        </Modal>
      )}
      {showUnArchiveSection && (
        <Modal
          extraClass={"small"}
          modalHeading={`Unarchive Hero Slider`}
          setShowModalContent={setShowUnArchiveSection}
        >
          <UnArchiveHeroSlider
            setShowUnArchiveSection={setShowUnArchiveSection}
            targetID={targetID}
            triggerFetch={triggerFetch}
          />
        </Modal>
      )}
    </div>
  );
}

export default HeroSlider;
