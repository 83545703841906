import { useGetInfo } from "antopolis-react-utils/hooks";
import { useAxiosInstance } from "../../../../../../Utilities/Hooks/useAxiosInstance";
import { HERO_SLIDERS_API } from '../../../../../../Utilities/APIs/WebsiteContentsAPIs/WebsiteContentsAPIs';

function ArchiveHeroSlider({
    api,
    targetID,
    setShowArchiveSection,
    setShowModal,
    triggerFetch

}) {
    const axiosInstance = useAxiosInstance();


    const { info: company } = useGetInfo({
        axiosInstance: axiosInstance,
        api: HERO_SLIDERS_API + "singleHeroSlider/" + targetID,
    });


    async function handleArchive() {
        const response = await axiosInstance.put(
            HERO_SLIDERS_API + "archiveSingleHeroSlider/" + targetID,
        );

        if (response) {
            triggerFetch();
            setShowArchiveSection(false);
        }
    }
    return (
        <div className="modal_delete_content">
            <p className="text-gray-200">Are you sure you want to archive {company?.name}?</p>

            <div className="modal_group_buttons">
                <button
                    className="submit"
                    onClick={() => {
                        handleArchive();
                    }}
                >
                    Archive
                </button>

                <button
                    className="cancel"
                    onClick={() => {
                        setShowModal(false);
                        setShowArchiveSection(false);
                    }}
                >
                    Cancel
                </button>
            </div>
        </div>
    );
}

export default ArchiveHeroSlider;
