import InputBoilerplate from "../InputBoilerplate"

function DateInput({label, value, setState}) {
    return (
        <InputBoilerplate
            label={label}
            type={'date'}
            value={value}
            setState={setState}
        />
    )
}

export default DateInput