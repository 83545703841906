import FormTop from "../../../../Partials/Forms/FormTop/FormTop"
import Navigate from "../../../../Partials/Navigate/Navigate"

function CheckEmail({ email }) {
    return (
        <div className="form_wrapper">
            <FormTop
                title='Check your email'
                description={`If we found an account with ${email ? email : ''}, an email has been sent. Please check your email in a moment.`}
            />
            <Navigate route='/auth/login' text='Go Back Login' />
        </div>
    )
}

export default CheckEmail