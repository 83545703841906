import { createContext, useState } from "react";

const SidebarContext = createContext({})


function SidebarContextProvider({ children }) {
    const [showSidebar, setShowSidebar] = useState(false);
  
    return (
        <SidebarContext.Provider
            value={{
                showSidebar,
                setShowSidebar
            }}
        >
            {children}
        </SidebarContext.Provider>
    )
}

export {
    SidebarContextProvider
}

export default SidebarContext;