/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useMemo, useState } from "react";
import { AuthContext } from "../../../../../Contexts/GlobalContexts/AuthContext";
import { useAxiosInstance } from "../../../../../Utilities/Hooks/useAxiosInstance";
import { useGetInfo } from "antopolis-react-utils/hooks";
import { useNavigate } from "react-router-dom";
import BackButton from "../../../../Partials/Elements/BackButton/BackButton";
import { CRUDTable } from "../../../../Partials/Layout/CRUD/CRUDTable/CRUDTable";
import { CRUDTableHeader } from "../../../../Partials/Layout/CRUD/CRUDTableHeader/CRUDTableHeader";
import { CRUDth } from "../../../../Partials/Layout/CRUD/CRUDth/CRUDth";
import { ShortTextCell } from "../../../../Partials/Layout/CRUD/ShortTextCell/ShortTextCell";
import SelectInput from "../../../../Partials/Forms/FormInputs/SelectInput/SelectInput";
import SelectOption from "../../../../Partials/Forms/FormInputs/SelectInput/SelectOption/SelectOption";
import { CRUDButton } from "../../../../Partials/Layout/CRUD/CRUDButtons/CRUDButtons";
import { Modal } from "../../../../Partials/Elements/Modal/Modal";
import ViewReceipt from "./ViewReceipt";
import ApproveTokenRequest from "./ApproveTokenRequest";
import RejectTokenRequest from "./RejectTokenRequest";
import { MANAGE_TOKEN_REQUESTS_API } from "../../../../../Utilities/APIs/APIs";
import { takaFormatter } from "./../../../../../Utilities/HelperFunctions/takaFormatter";
import { formatDate } from "./../../../../../Utilities/HelperFunctions/formatDate";
import { DataContext } from "../../../../../Contexts/GlobalContexts/DataContext";
import { IoClose } from "react-icons/io5";
import { FaCheck } from "react-icons/fa6";

function PendingRequest({ api }) {
  const [selectedReceipt, setSelectedReceipt] = useState(null);
  const [showReceiptSection, setShowReceiptSection] = useState(false);
  const [showRejectSection, setShowRejectSection] = useState(false);
  const [showApproveSection, setShowApproveSection] = useState(false);
  const { toggleFetch, triggerFetch } = useContext(DataContext);

  const [tokenRequests, setTokenRequests] = useState([]);

  const [filter, setFilter] = useState("buy");
  const [targetID, setTargetID] = useState(null);

  const { employee } = useContext(AuthContext);
  const axiosInstance = useAxiosInstance();

  const { info } = useGetInfo({
    axiosInstance: axiosInstance,
    api: MANAGE_TOKEN_REQUESTS_API + "getAllPendingRequest",
    toggleFetch,
  });

  useEffect(() => {
    info?.success && setTokenRequests(info.data);
  }, [info]);

  console.log(info?.success);

  const navigate = useNavigate();

  const handleFilterChange = (value) => {
    setFilter(value);
  };

  const handleImageClick = (img) => {
    setSelectedReceipt(img);
    setShowReceiptSection(true);
  };

  const filteredTokenRequests = useMemo(() => {
    if (!tokenRequests) return [];
    return tokenRequests
      .filter((request) => {
        if (filter === "buy") return request.requestType === "buy";
        if (filter === "sell") return request.requestType === "sell";
        return true;
      })
      .sort((a, b) => (a.name || "").localeCompare(b.name || ""));
  }, [tokenRequests, filter]);

  return (
    <div className="screen_wrapper">
      <div className="screen_header">
        <div
          style={{
            flex: 1,
            display: "flex",
            alignItems: "center",
            gap: "20px",
            justifyContent: "flex-start",
          }}
        >
          <BackButton />
          <h1 className="screen_heading">{`${filter.toUpperCase()} Requests (${
            filteredTokenRequests.length
          } in total)`}</h1>
        </div>

        <div className="flex-1 flex justify-center">
          <div style={{ display: "flex", gap: "20px" }}>
            <SelectInput
              label={""}
              value={filter}
              placeholder={"Please Select Filter"}
              setState={handleFilterChange}
            >
              <SelectOption optionValue={"buy"} optionText={"Buying"} />
              <SelectOption optionValue={"sell"} optionText={"Selling"} />
            </SelectInput>
          </div>
        </div>

        <div style={{ display: "flex", gap: "20px", flex: 1 }}>
          {/* <CreateButton
                        screenTopicSingular={"Investment User"}
                        setShowCreateForm={setShowCreateForm}
                    /> */}
        </div>
      </div>

      <CRUDTable>
        <CRUDTableHeader>
          <CRUDth align={"left"} th="Name" />
          <CRUDth th="Nano Asset" />
          <CRUDth th="Quantity" />
          <CRUDth th="Total" />
          <CRUDth th="Date of Request" />
          <CRUDth th="Receipt" />
          <CRUDth th="Actions" />
        </CRUDTableHeader>
        <tbody>
          {filteredTokenRequests.map((tokenRequest, indx) => (
            <tr key={indx}>
              <td>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <img
                    style={{
                      height: "30px",
                      width: "30px",
                      borderRadius: "50%",
                    }}
                    src={
                      tokenRequest.image
                        ? process.env.VITE_REACT_APP_SPACES_URL +
                          tokenRequest?.tokenInvestor?.image
                        : `https://ui-avatars.com/api/?name=${tokenRequest?.tokenInvestor?.name}&background=random&length=1`
                    }
                    alt={tokenRequest.tokenInvestor?.name}
                  />
                  <p>{tokenRequest.tokenInvestor?.name}</p>
                </div>
              </td>
              <ShortTextCell text={tokenRequest.token?.tokenName} />
              <ShortTextCell text={tokenRequest?.quantity} />
              <ShortTextCell text={takaFormatter(tokenRequest.totalAmount)} />
              {/* <ShortTextCell text={(tokenRequest.createdAt) ? new Date(tokenRequest.createdAt).toLocaleDateString() : ""} /> */}
              <ShortTextCell text={formatDate(tokenRequest.createdAt)} />
              <td>
                <div
                  onClick={() => handleImageClick(tokenRequest.receipt)}
                  className="flex justify-center items-center cursor-pointer"
                >
                  <img
                    src={
                      process.env.REACT_APP_SPACES_URL + tokenRequest?.receipt
                    }
                    alt="receipt"
                    className="w-28 h-28"
                  />
                </div>
              </td>
              <td className="action_buttons_wrapper">
                <div className="action_buttons wc_investor_action_buttons">
                  <CRUDButton
                    tooltipId={"pending-request-approve-btn"}
                    tooltipContent={"Approve Request"}
                    handleClick={() => {
                      setTargetID(tokenRequest._id);
                      setShowApproveSection(true);
                    }}
                  >
                    <FaCheck size={25} />
                  </CRUDButton>
                  <CRUDButton
                    tooltipId={"pending-request-reject-btn"}
                    tooltipContent={"Reject Request"}
                    handleClick={() => {
                      setTargetID(tokenRequest._id);
                      setShowRejectSection(true);
                    }}
                  >
                    <IoClose size={30} />
                  </CRUDButton>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </CRUDTable>
      {showReceiptSection && (
        <Modal
          extraClass={"small"}
          modalHeading={`View Receipt`}
          setShowModalContent={setShowReceiptSection}
        >
          <ViewReceipt
            imageSrc={process.env.REACT_APP_SPACES_URL + selectedReceipt}
          />
        </Modal>
      )}

      {showApproveSection && (
        <Modal
          extraClass={"small"}
          modalHeading={`Approve Token Request`}
          setShowModalContent={setShowApproveSection}
        >
          <ApproveTokenRequest
            targetID={targetID}
            setShowApproveSection={setShowApproveSection}
            triggerFetch={triggerFetch}
          />
        </Modal>
      )}

      {showRejectSection && (
        <Modal
          extraClass={"small"}
          modalHeading={`Reject Token Request`}
          setShowModalContent={setShowRejectSection}
        >
          <RejectTokenRequest
            targetID={targetID}
            setShowRejectSection={setShowRejectSection}
            triggerFetch={triggerFetch}
          />
        </Modal>
      )}
    </div>
  );
}
export default PendingRequest;
