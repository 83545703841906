import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAxiosInstance } from "../../../../../../Utilities/Hooks/useAxiosInstance";
import BackButton from "../../../../../Partials/Elements/BackButton/BackButton";
import { CreateButton } from "../../../../../Partials/Layout/CRUD/CreateButton/CreateButton";
import { CRUDTable } from "../../../../../Partials/Layout/CRUD/CRUDTable/CRUDTable";
import { CRUDTableHeader } from "../../../../../Partials/Layout/CRUD/CRUDTableHeader/CRUDTableHeader";
import { CRUDth } from "../../../../../Partials/Layout/CRUD/CRUDth/CRUDth";
import { Modal } from "../../../../../Partials/Elements/Modal/Modal";
import { ShortTextCell } from "../../../../../Partials/Layout/CRUD/ShortTextCell/ShortTextCell";
import { MANAGE_TOKEN_IMAGES_API } from "../../../../../../Utilities/APIs/APIs";
import CreateTokenImage from "./CreateTokenImage";


function TokenImages() {
    const [toggleFetch, setToggleFetch] = useState(false);
    const [tokenImages, setTokenImages] = useState(null);
    const { id: tokenID } = useParams();

    const [showCreateForm, setShowCreateForm] = useState(false);
    const [showUpdateForm, setShowUpdateForm] = useState(false);
    const [showSingleDocument, setShowSingleDocument] = useState(false);
    const [imageSrc, setImageSrc] = useState(null);

    // const { employee } = useContext(AuthContext);
    const axiosInstance = useAxiosInstance();

    useEffect(() => {
        async function fetchAndSetTokenImages() {
            const { data } = await axiosInstance.get(
                `${MANAGE_TOKEN_IMAGES_API}getImagesOfSingleToken/${tokenID}`
            );
            setTokenImages(data.data);
        }
        fetchAndSetTokenImages();
    }, [axiosInstance, toggleFetch, tokenID]);

    function triggerFetch() {
        setToggleFetch((prevState) => !prevState);
    }

    return (
        <div className="screen_wrapper">
            <div className="screen_header">
                <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                    <BackButton />
                    <h1 className="screen_heading">{`Documents (${tokenImages?.length} in total)`}</h1>
                </div>
                <CreateButton
                    screenTopicSingular={"Document"}
                    setShowCreateForm={setShowCreateForm}
                />
            </div>
            <CRUDTable>
                <CRUDTableHeader>
                    <th>Filename</th>
                    <CRUDth th="File" />
                    <CRUDth th="Date" />
                </CRUDTableHeader>
                <tbody>
                    {tokenImages &&
                        tokenImages.map((doc) => (
                            <tr key={doc._id}>
                                <td> {doc.imageName}</td>
                                {/* <td>{doc.image.split("-").pop()}</td> */}
                                <td style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "0px" }}>
                                    <img style={{ height: "40px", width: "40px" }} src={process.env.REACT_APP_SPACES_URL + doc?.image} alt="token" className="!h-20 !w-20" />
                                </td>
                                <ShortTextCell text={new Date(doc.createdAt).toLocaleDateString()} />
                            </tr>
                        ))}
                </tbody>
            </CRUDTable>

            {showUpdateForm && (
                <Modal
                    extraClass={"small"}
                    modalHeading={`Update Token Images`}
                    setShowModalContent={setShowUpdateForm}
                >
                    {/* Update Component can be included here */}
                </Modal>
            )}

            {showCreateForm && (
                <Modal
                    extraClass={"small"}
                    modalHeading={`Add new document`}
                    setShowModalContent={setShowCreateForm}
                >

                    <CreateTokenImage targetID={tokenID} setShowEditSection={setShowCreateForm} triggerFetch={triggerFetch} />
                </Modal>
            )}

            {
                showSingleDocument && (
                    <Modal
                        extraClass={"medium"}
                        modalHeading={`View Document`}
                        setShowModalContent={setShowSingleDocument}
                    >
                        {/* View Component can be included here */}
                    </Modal>
                )
            }

        </div>
    );
}

export default TokenImages;
