export function CRUDTable({ children }) {
    return (
        <div className='data_table_wrapper' >
            <div className="data_table_inner">
                <table className="data_table">
                    {children}
                </table>
            </div>
        </div>
    )
}