import { useEffect, useState } from "react";
import { useAxiosInstance } from "../../../../../../Utilities/Hooks/useAxiosInstance";

function DeleteInvite({ employee, api, setShowDeleteInviteSection, targetID, triggerFetch }) {
    const [email, setEmail] = useState("");
    const axiosInstance = useAxiosInstance();

    useEffect(() => {
        async function fetchAndSetEmail() {
            const { data } = await axiosInstance.get(
                api + "getSingleInvestorUser/" + targetID
            );
            setEmail(data.email);
        }

        fetchAndSetEmail();
    }, [targetID, axiosInstance, api]);

    async function handleResend() {
        // const itemData = { email };

        // const response = await axiosInstance.post(api + "resendInvite", itemData);
        const response = await axiosInstance.delete(api + "deleteInvite/" + targetID);

        if (response) {
            // setEmail("");
            triggerFetch();
            setShowDeleteInviteSection(false);
        }
    }

    return (
        <div className="modal_delete_content">
            <p style={{ color: "#fff", textAlign: "center" }}>Are you sure you want to delete invite to ?</p>
            <p style={{ fontSize: "12px", color: "#fff", textAlign: "center" }}>{email ? email : ""}</p>

            <div className="modal_group_buttons">
                <button
                    className="submit"
                    onClick={() => {
                        handleResend();
                    }}
                >
                    Delete Invite
                </button>

                <button
                    className="cancel"
                    onClick={() => {
                        setShowDeleteInviteSection(false);
                    }}
                >
                    Cancel
                </button>
            </div>
        </div>
    );
}

export default DeleteInvite;
