import { useOrientation } from "@uidotdev/usehooks"

export function useUseOrientation() {

    const orientation = useOrientation()

    const orientationType = {
        portrait: orientation.type === 'portrait-primary',
        landscape: orientation.type === 'landscape-primary'
    }
    
    return orientationType
}