import { useContext } from "react"
import { useForgotPassword } from "antopolis-react-utils/hooks/auth"
import { FORGOT_PASSWORD_API } from "../../../../Utilities/APIs/AuthAPIs/AuthAPIs"
import useAxiosAuthInstance from "../../../../Utilities/Hooks/axiosAuthInstance"
import ForgotPasswordForm from "./ForgotPasswordForm/ForgotPasswordForm"
import Navigate from "../../../Partials/Navigate/Navigate"
import FormTop from "../../../Partials/Forms/FormTop/FormTop"
import CheckEmail from "./CheckEmail/CheckEmail"
import { AuthContext } from "../../../../Contexts/GlobalContexts/AuthContext"


function ForgotPassword() {

    const axiosAuthInstance = useAxiosAuthInstance()
    const { setError } = useContext(AuthContext)


    const {
        email,
        setEmail,
        emailSent,
        handleSubmit
    } = useForgotPassword({
        axiosInstance: axiosAuthInstance,
        api: FORGOT_PASSWORD_API,
        setErrorMessage: setError
    })

    return (
        <>
            {
                emailSent ?
                    <CheckEmail email={email} />
                    :
                    <div className="form_wrapper">
                        <FormTop
                            title='Forgot Password'
                            description="Enter your email address below and we’ll send you a link to reset your password"
                        />
                        <ForgotPasswordForm
                            handleSubmit={handleSubmit}
                            email={email}
                            setEmail={setEmail}
                        />
                        <Navigate route='/auth/login' text='Go Back?' />
                    </div>
            }
        </>
    )
}

export default ForgotPassword