import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAxiosInstance } from "../../../../../../../../Utilities/Hooks/useAxiosInstance";
import { MANAGE_INVESTMENT_DOCUMENTS_API } from "../../../../../../../../Utilities/APIs/APIs";
import { AuthContext } from "../../../../../../../../Contexts/GlobalContexts/AuthContext";
import BackButton from "../../../../../../../Partials/Elements/BackButton/BackButton";
import { CreateButton } from "../../../../../../../Partials/Layout/CRUD/CreateButton/CreateButton";
import { CRUDTable } from "../../../../../../../Partials/Layout/CRUD/CRUDTable/CRUDTable";
import { CRUDTableHeader } from "../../../../../../../Partials/Layout/CRUD/CRUDTableHeader/CRUDTableHeader";
import { CRUDth } from "../../../../../../../Partials/Layout/CRUD/CRUDth/CRUDth";
import { ShortTextCell } from "../../../../../../../Partials/Layout/CRUD/ShortTextCell/ShortTextCell";
import { ImageCell } from "../../../../../../../Partials/Layout/CRUD/ImageCell/ImageCell";
import { Modal } from "../../../../../../../Partials/Elements/Modal/Modal";
// import UploadInvestmentDocs from "../Trash_UploadInvestmentDocs";
import CreateInvestmentDoc from "./CreateDocuments";
import ViewDocument from "./ViewDocument";
import { ArchiveButton, CRUDButton, DeleteButton, EditButton } from "../../../../../../../Partials/Layout/CRUD/CRUDButtons/CRUDButtons";
import UpdateDocument from "./UpdateDocument";
import ArchiveDocument from "./ArchiveDocument";
import { HiArchiveBoxXMark } from "react-icons/hi2";

function InvestmentDocuments() {
    const [toggleFetch, setToggleFetch] = useState(false);
    const [investmentDocuments, setInvestmentDocuments] = useState(null);
    const { id: investmentId } = useParams();
    const [targetID, setTargetID] = useState(null);

    const [showCreateForm, setShowCreateForm] = useState(false);
    const [showUpdateForm, setShowUpdateForm] = useState(false);
    const [showArchiveForm, setShowArchiveForm] = useState(false);
    const [showSingleDocument, setShowSingleDocument] = useState(false);
    const [imageSrc, setImageSrc] = useState(null);

    const { employee } = useContext(AuthContext);
    const axiosInstance = useAxiosInstance();

    useEffect(() => {
        async function fetchAndSetInvestmentDocuments() {
            const { data } = await axiosInstance.get(
                `${MANAGE_INVESTMENT_DOCUMENTS_API}/${investmentId}`
            );
            setInvestmentDocuments(data);
        }
        fetchAndSetInvestmentDocuments();
    }, [axiosInstance, investmentId, toggleFetch]);

    function triggerFetch() {
        setToggleFetch((prevState) => !prevState);
    }


    const handleImageClick = (img) => {
        setImageSrc(img);
        setShowSingleDocument(true);
    };

    const renderFileThumbnail = (doc) => {
        const fileExtension = doc.file.split(".").pop().toLowerCase();

        if (["jpg", "jpeg", "png", "gif"].includes(fileExtension)) {
            return (
                <img
                    onClick={() => handleImageClick(process.env.REACT_APP_SPACES_URL + doc.file)}
                    style={{ borderRadius: "0px", height: "40px", width: "40px", cursor: "pointer" }}
                    src={process.env.REACT_APP_SPACES_URL + doc.file}
                    alt={doc.file.split("-").pop()}
                />
            );
        } else if (fileExtension === "pdf") {
            return (
                <a
                    href={process.env.REACT_APP_SPACES_URL + doc.file}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 underline"
                >
                    View PDF
                </a>
            );
        } else {
            return <p>Unsupported file type</p>;
        }
    };

    return (
        <div className="screen_wrapper">
            <div className="screen_header">
                <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                    <BackButton />
                    <h1 className="screen_heading">{`Documents (${investmentDocuments?.length} in total)`}</h1>
                </div>
                <CreateButton
                    screenTopicSingular={"Document"}
                    setShowCreateForm={setShowCreateForm}
                />
            </div>
            <CRUDTable>
                <CRUDTableHeader>
                    <CRUDth align={"left"} th="Upload date" />
                    <CRUDth align={"left"} th="Name" />
                    <CRUDth th="File" />
                    <CRUDth th="Uploaded By" />
                    <CRUDth th="Actions" />

                </CRUDTableHeader>
                <tbody>
                    {investmentDocuments &&
                        investmentDocuments.map((doc) => (
                            <tr key={doc._id}>
                                {/* <ShortTextCell text={doc.file.split("-").pop()} /> */}
                                <td className="text-left">{new Date(doc.createdAt).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: '2-digit' })}</td>

                                <td>{doc.documentName}</td>
                                <td className="">
                                    <div className="action_buttons">
                                        {renderFileThumbnail(doc)}
                                    </div>
                                </td>
                                <ShortTextCell text={doc.createdBy.name || "Admin"} />
                                {/* <td style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "0px" }} className="text-center">{renderFileThumbnail(doc)}</td> */}
                                <td className="">
                                    <div className="action_buttons ">
                                        <EditButton
                                            setShowUpdateForm={setShowUpdateForm}
                                            targetID={doc._id}
                                            setTargetID={setTargetID}
                                        />
                                        {/* <CRUDButton handleClick={() => {
                                            setShowArchiveForm(true)
                                            setTargetID(targetID)
                                        }
                                        }>
                                            <p style={{ fontSize: "16px", color: "#FFE09D", textAlign: "center" }}><HiArchiveBoxXMark /></p>
                                        </CRUDButton> */}

                                        <DeleteButton
                                            setShowDeleteSection={setShowArchiveForm}
                                            targetID={doc._id}
                                            setTargetID={setTargetID}
                                        />


                                    </div>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </CRUDTable>

            {showUpdateForm && (
                <Modal
                    extraClass={"small"}
                    modalHeading={`Update Investment`}
                    setShowModalContent={setShowUpdateForm}
                >
                    <UpdateDocument
                        targetID={investmentId}
                        documentID={targetID}
                        setShowEditSection={setShowUpdateForm}
                        triggerFetch={triggerFetch}
                    />
                </Modal>
            )}
            {showArchiveForm && (
                <Modal
                    extraClass={"small"}
                    modalHeading={`Archive Document`}
                    setShowModalContent={setShowArchiveForm}
                >
                    <ArchiveDocument
                        targetID={investmentId}
                        documentID={targetID}
                        setShowEditSection={setShowArchiveForm}
                        triggerFetch={triggerFetch}
                    />
                </Modal>
            )}

            {showCreateForm && (
                <Modal
                    extraClass={"small"}
                    modalHeading={`Add new document`}
                    setShowModalContent={setShowCreateForm}
                >
                    <CreateInvestmentDoc
                        targetID={investmentId}
                        setShowEditSection={setShowCreateForm}
                        triggerFetch={triggerFetch}

                    />
                </Modal>
            )}

            {
                showSingleDocument && (
                    <Modal
                        extraClass={"medium"}
                        modalHeading={`View Document`}
                        setShowModalContent={setShowSingleDocument}
                    >
                        <ViewDocument imageSrc={imageSrc} />
                    </Modal>
                )
            }

        </div>
    );
}

export default InvestmentDocuments;
