import { useAxiosInstance } from "../../../../../Utilities/Hooks/useAxiosInstance";
import { useState } from "react";
import Form from "../../../../Partials/Forms/Form";
import DateInput from "../../../../Partials/Forms/FormInputs/DateInput/DateInput";
import NumberInput from "../../../../Partials/Forms/FormInputs/NumberInput/NumberInput";
import ShortTextInput from "../../../../Partials/Forms/FormInputs/ShortTextInput/ShortTextInput";
import FormSubmitButton from "../../../../Partials/Forms/FormSubmitButton/FormSubmitButton";
import ImageInput from "../../../../Partials/Forms/FormInputs/ImageInput/ImageInput";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import SelectInput from "../../../../Partials/Forms/FormInputs/SelectInput/SelectInput";
import SelectOption from "../../../../Partials/Forms/FormInputs/SelectInput/SelectOption/SelectOption";

export default function CreateToken({
  api,
  targetID,
  setShowEditSection,
  triggerFetch,
}) {
  const axiosInstance = useAxiosInstance();

  // Form state
  const [tokenName, setTokenName] = useState("");
  const [thumbnail, setThumbnail] = useState(null);
  const [tokenDescription, setTokenDescription] = useState("");
  const [totalIssuedTokens, setTotalIssuedTokens] = useState(0);
  const [lockInPeriod, setLockInPeriod] = useState(0);
  const [tenure, setTenure] = useState(0);
  const [riskScore, setRiskScore] = useState("Low");
  const [finalInvestmentDate, setFinalInvestmentDate] = useState(new Date());
  const [initialTokenValue, setInitialTokenValue] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [roi, setRoi] = useState(0);
  const [category, setCategory] = useState("");

  async function handleSubmit(e) {
    e.preventDefault();

    const formData = new FormData();
    formData.append("investor", targetID);
    formData.append("tokenName", tokenName);
    formData.append("thumbnail", thumbnail);
    formData.append("tokenDescription", tokenDescription);
    formData.append("totalIssuedTokens", totalIssuedTokens);
    formData.append("lockInPeriod", lockInPeriod);
    formData.append("tenure", tenure);
    formData.append("riskScore", riskScore);
    formData.append("finalInvestmentDate", finalInvestmentDate);
    formData.append("initialTokenValue", initialTokenValue);
    formData.append("quantity", quantity);
    formData.append("roi", roi);
    formData.append("category", category);

    try {
      const response = await axiosInstance.post(api + "createToken", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response?.data) {
        // Reset form
        setTokenName("");
        setThumbnail(null);
        setTokenDescription("");
        setTotalIssuedTokens(0);
        setLockInPeriod(0);
        setTenure(0);
        setRiskScore("Low");
        setFinalInvestmentDate(new Date());
        setInitialTokenValue(0);
        setQuantity(0);
        setRoi(0);
        setCategory("");
        setShowEditSection(false);
        triggerFetch();
      }
    } catch (error) {
      console.error("Error creating token:", error);
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      <div className="grid grid-cols-2 gap-20 p-10">
        <div className="">
          <ShortTextInput
            label={"1. Nano Asset Name"}
            value={tokenName}
            placeholder={"Enter Nano Asset Name"}
            setState={setTokenName}
          />
          <NumberInput
            label={"2. Quantity"}
            value={quantity}
            placeholder={"Enter Total Quantity"}
            setState={setQuantity}
          />
          <NumberInput
            label={"3. Total Issued Nano Assets"}
            value={totalIssuedTokens}
            placeholder={"Enter Total Issued Nano Assets"}
            setState={setTotalIssuedTokens}
          />
          <NumberInput
            label={"4. Maturity Period (Days)"}
            value={lockInPeriod}
            placeholder={"Enter Maturity Period in Days"}
            setState={setLockInPeriod}
          />
          <SelectInput
            label={"5. Dividend"}
            placeholder={"Select an Investor"}
            required
            setState={setTenure}
          >
            <SelectOption optionValue={""} optionText={"Select Dividend"} />
            <SelectOption optionValue={30} optionText={"Monthly"} />
            <SelectOption optionValue={120} optionText={"Quarterly"} />
            <SelectOption optionValue={180} optionText={"Semi Annual"} />
            <SelectOption optionValue={365} optionText={"Annual"} />
          </SelectInput>

          <SelectInput
            label={"6. Risk Score"}
            placeholder={"Select a Risk Score"}
            required
            setState={setRiskScore}
          >
            <SelectOption optionValue={0} optionText={"Select Risk Score"} />
            <SelectOption optionValue={"Low"} optionText={"Low"} />
            <SelectOption optionValue={"Medium"} optionText={"Medium"} />
            <SelectOption optionValue={"High"} optionText={"High"} />
          </SelectInput>

          <SelectInput
            label={"7. Category"}
            placeholder={"Select a Category"}
            required
            setState={setCategory}
          >
            <SelectOption optionValue={""} optionText={"Select Category"} />
            <SelectOption optionValue={"Real Estate"} optionText={"Real Estate"} />
            <SelectOption optionValue={"Business"} optionText={"Business"} />
            <SelectOption optionValue={"Working Capital"} optionText={"Working Capital"} />
            <SelectOption optionValue={"Trade Financing"} optionText={"Trade Financing"} />
            <SelectOption optionValue={"Capital Market and Mutual Funds"} optionText={"Capital Market and Mutual Funds"} />
            <SelectOption optionValue={"Gold and Silver"} optionText={"Gold and Silver"} />
          </SelectInput>

          <DateInput
            label={"8. Final Investment Date"}
            value={finalInvestmentDate}
            placeholder={"Enter Final Investment Date"}
            setState={setFinalInvestmentDate}
          />

          <NumberInput
            label={"9. Initial Nano Asset Value"}
            value={initialTokenValue}
            placeholder={"Enter Initial Nano Asset Value"}
            setState={setInitialTokenValue}
          />
        </div>
        <div>
          <ImageInput
            allowCreateImage
            state={thumbnail}
            setState={setThumbnail}
            fieldId={"tokenThumbnail"}
          >
            10. Nano Asset Thumbnail
          </ImageInput>

          <NumberInput
            label={"11. Annualized ROI (%)"}
            value={roi}
            placeholder={"Enter ROI"}
            setState={setRoi}
          />

          <div className="mt-10 form-group">
            <label className="input_field_label">12. Nano Asset Description</label>
            <CKEditor
              editor={ClassicEditor}
              data={tokenDescription}
              onChange={(event, editor) => {
                const data = editor.getData();
                setTokenDescription(data);
              }}
            />
          </div>
        </div>
      </div>

      <FormSubmitButton text="Create Nano Asset" />
    </Form>
  );
}
