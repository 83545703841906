// import { useEffect, useState } from "react";
// import { useAxiosInstance } from "../../../../../../../../Utilities/Hooks/useAxiosInstance";
// import { MANAGE_INVESTMENT_DOCUMENTS_API } from "../../../../../../../../Utilities/APIs/APIs";
// import Form from "../../../../../../../Partials/Forms/Form";
// import ShortTextInput from "../../../../../../../Partials/Forms/FormInputs/ShortTextInput/ShortTextInput";
// import ImageInput from "../../../../../../../Partials/Forms/FormInputs/ImageInput/ImageInput";
// import FormSubmitButton from "../../../../../../../Partials/Forms/FormSubmitButton/FormSubmitButton";

// export default function UpdateDocument({ targetID, documentID, setShowEditSection, triggerFetch }) {
//     const axiosInstance = useAxiosInstance();
//     const [file, setFile] = useState(null);
//     const [documentName, setDocumentName] = useState("");
//     const [isLoading, setIsLoading] = useState(false);
//     const [hasError, setHasError] = useState(false);

//     // Fetch the existing data of the document to populate the form
//     useEffect(() => {
//         async function fetchDocumentData() {
//             try {
//                 const response = await axiosInstance.get(
//                     MANAGE_INVESTMENT_DOCUMENTS_API + "getSingleDocument/" + documentID
//                 );
//                 if (response?.data) {
//                     setDocumentName(response.data.documentName);
//                     setFile(response.data.file); // Set file if available
//                 }
//             } catch (error) {
//                 console.error("Error fetching document data:", error);
//             }
//         }

//         fetchDocumentData();
//     }, [axiosInstance, documentID]);

//     async function handleSubmit(e) {
//         e.preventDefault();
//         setIsLoading(true);
//         setHasError(false);

//         const formData = new FormData();
//         formData.append('investment', targetID);
//         formData.append('documentName', documentName);

//         if (file) {
//             formData.append('file', file);
//         }

//         try {
//             const response = await axiosInstance.put(
//                 MANAGE_INVESTMENT_DOCUMENTS_API + "updateDocument/" + documentID,
//                 formData,
//                 {
//                     headers: {
//                         'Content-Type': 'multipart/form-data',
//                     },
//                 }
//             );

//             if (response?.data) {
//                 console.log(response.data);
//                 // Reset form or close the edit section
//                 setFile(null);
//                 setShowEditSection(false);
//                 setIsLoading(false);

//                 triggerFetch();
//             }
//         } catch (error) {
//             console.error("Error updating document:", error);
//             setIsLoading(false);
//             setHasError(true);
//         }
//     }

//     return (
//         <Form onSubmit={handleSubmit}>
//             <div>
//                 <ShortTextInput
//                     label={"Document Name"}
//                     value={documentName}
//                     setState={setDocumentName}
//                 />
//                 {/* <ImageInput
//                     allowUpdateImage
//                     state={file}
//                     setState={setFile}
//                     fieldId={"Investment Document"}
//                 >
//                     <p>Investment Document</p>
//                 </ImageInput> */}
//             </div>

//             <FormSubmitButton text={isLoading ? "Updating..." : "update"} disabled={isLoading || hasError}>
//                 {isLoading ? (
//                     <div className="flex items-center space-x-2">
//                         <div className="w-4 h-4 border-2 border-t-2 border-t-transparent border-gray-500 rounded-full animate-spin"></div>
//                         <span>Updating...</span>
//                     </div>
//                 ) : hasError ? (
//                     <div className="text-red-500">Failed to update</div>
//                 ) : (
//                     "update"
//                 )}
//             </FormSubmitButton>
//         </Form>
//     );
// }

import { useEffect, useState } from "react";
import { useAxiosInstance } from "../../../../../../../../Utilities/Hooks/useAxiosInstance";
import { MANAGE_INVESTMENT_DOCUMENTS_API } from "../../../../../../../../Utilities/APIs/APIs";
import Form from "../../../../../../../Partials/Forms/Form";
import ShortTextInput from "../../../../../../../Partials/Forms/FormInputs/ShortTextInput/ShortTextInput";
import FormSubmitButton from "../../../../../../../Partials/Forms/FormSubmitButton/FormSubmitButton";

export default function UpdateDocument({
  targetID,
  documentID,
  setShowEditSection,
  triggerFetch,
}) {
  const axiosInstance = useAxiosInstance();
  const [documentName, setDocumentName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    async function fetchDocumentData() {
      try {
        const response = await axiosInstance.get(
          MANAGE_INVESTMENT_DOCUMENTS_API + "getSingleDocument/" + documentID
        );
        if (response?.data) {
          setDocumentName(response.data.documentName);
        }
      } catch (error) {
        console.error("Error fetching document data:", error);
      }
    }

    fetchDocumentData();
  }, [axiosInstance, documentID]);

  async function handleSubmit(e) {
    e.preventDefault();
    setIsLoading(true);
    setHasError(false);

    const formData = {
      investment: targetID,
      documentName: documentName,
    };

    try {
      const response = await axiosInstance.put(
        MANAGE_INVESTMENT_DOCUMENTS_API + "updateDocument/" + documentID,
        formData
      );

      if (response?.data) {
        console.log(response.data);
        // Reset form or close the edit section
        setShowEditSection(false);
        setIsLoading(false);
        triggerFetch();
      }
    } catch (error) {
      console.error("Error updating document name:", error);
      setIsLoading(false);
      setHasError(true);
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      <div>
        <ShortTextInput
          label={"Document Name"}
          value={documentName}
          setState={setDocumentName}
        />
      </div>

      <FormSubmitButton
        text={isLoading ? "Updating..." : "Update"}
        disabled={isLoading || hasError}
      >
        {isLoading ? (
          <div className="flex items-center space-x-2">
            <div className="w-4 h-4 border-2 border-t-2 border-t-transparent border-gray-500 rounded-full animate-spin"></div>
            <span>Updating...</span>
          </div>
        ) : hasError ? (
          <div className="text-red-500">Failed to update</div>
        ) : (
          "Update"
        )}
      </FormSubmitButton>
    </Form>
  );
}
