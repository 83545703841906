import { Link } from "react-router-dom";
import logoImg from "../../../../../../../assets/logos/logo.png";
import logoIcon from "../../../../../../../assets/logos/logoIcon.png";
import wideLogoImg from "../../../logo-wide.svg";
import { Image } from "../../../../../Elements/Image/Image";

function SidebarLogo({ handleClick, expandSidebar }) {
  return (
    <Link href="/" onClick={handleClick}>
      {!expandSidebar ? (
        // <Image
        //     imgLink={logoImg}
        //     imgAlt={'brand'}
        //     className={'logo'}
        //     assetOrWeb
        // />
        <p className="logo">
          {" "}
          <img src={logoIcon} alt="logo" style={{ width: "36px" }} />
        </p>
      ) : (
        // <Image
        //     imgLink={wideLogoImg}
        //     imgAlt={'brand'}
        //     className={'logo_wide'}
        //     assetOrWeb
        // />
        <p className="logo">
          {" "}
          <img src={logoImg} alt="logo" style={{ width: "150px" }} />
        </p>
      )}
    </Link>
  );
}

export default SidebarLogo;
