import { Link } from 'react-router-dom'
import './FormTop.css'
import Navigate from '../../Navigate/Navigate'

function FormTop({ title, description }) {
    return (
        <div className="form_top_content">
            <h1 >{title}</h1>
            {
                description &&
                <div className='register_account'>
                    <p>{description}</p>
                </div>
            }
        </div>
    )
}

export default FormTop