import "./SelectInput.css";

function SelectInput({ label, value, setState, children }) {
  return (
    <div className="input_group">
      <label className="input_field_label">{label}</label>
      <select
        className="input_field"
        value={value}
        onChange={(e) => setState(e.target.value)}
      >
        {children}
      </select>
    </div>
  );
}

export default SelectInput;
