import { useEffect, useState } from "react";
import { useAxiosInstance } from "../../../../../../Utilities/Hooks/useAxiosInstance";
import "./InviteTokenInvestors.css";

function ResendInvite({ employee, api, setShowResendSection, targetID }) {
  const [email, setEmail] = useState("");
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetEmail() {
      const { data } = await axiosInstance.get(
        api + "getSingleInvite/" + targetID
      );
      setEmail(data.email);
    }

    fetchAndSetEmail();
  }, [targetID, axiosInstance, api]);

  async function handleResend() {
    const itemData = { email };

    const response = await axiosInstance.post(api + "resendInvite/" + targetID, itemData);

    if (response) {
      setEmail("");
      setShowResendSection(false);
    }
  }

  return (
    <div className="modal_delete_content">
      <p style={{ color: "#fff", textAlign: "center" }}>Are you sure you want to resend invite to ?</p>
      <p style={{ fontSize: "12px", color: "#fff", textAlign: "center" }}>{email ? email : ""}</p>
      <div className="modal_group_buttons">
        <button
          className="submit"
          onClick={() => {
            handleResend();
          }}
        >
          Resend Invite
        </button>

        <button
          className="cancel"
          onClick={() => {
            setShowResendSection(false);
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  );
}

export default ResendInvite;
