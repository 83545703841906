import { useGetInfo } from "antopolis-react-utils/hooks";
import { useAxiosInstance } from "../../../../../../Utilities/Hooks/useAxiosInstance";
import { useContext } from "react";
import { LayoutContext } from "../../../../../../Contexts/GlobalContexts/LayoutContext";

function ArchiveInvestor({
    api,
    targetID,
    setShowArchiveSection,
    setShowModal,
    triggerFetch

}) {
    const axiosInstance = useAxiosInstance();

    // const { triggerFetch } = useContext(LayoutContext);

    async function handleDelete() {
        const response = await axiosInstance.put(api + "archiveSingleInvestor/" + targetID);

        if (response) {
            triggerFetch();
            setShowArchiveSection(false);
        }
    }
    return (
        <div className="modal_delete_content">
            <p className="text-gray-200">Are you sure you want to archive this investor?</p>

            <div className="modal_group_buttons">
                <button
                    className="submit"
                    onClick={() => {
                        handleDelete();
                    }}
                >
                    Archive
                </button>

                <button
                    className="cancel"
                    onClick={() => {
                        setShowModal(false);
                        setShowArchiveSection(false);
                    }}
                >
                    Cancel
                </button>
            </div>
        </div>
    );
}

export default ArchiveInvestor;
