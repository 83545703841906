import { useState } from "react";
import { useAxiosInstance } from "../../../../../../Utilities/Hooks/useAxiosInstance";
import { useGetInfo } from "antopolis-react-utils/hooks";

function UnAssignInvestorUser({ employee, api, setShowDeleteSection, targetID, triggerFetch }) {

    const [toggleFetch, setToggleFetch] = useState(false);

    const axiosInstance = useAxiosInstance();
    const { info: investorUsers } = useGetInfo({
        axiosInstance: axiosInstance,
        api: api + "getSingleInvestorUserInvestor/" + targetID,
        toggleFetch,
    });


    async function handleUnAssign() {
        const response = await axiosInstance.delete(
            api + "removeInvestorFromInvestorUser/" + targetID
        );

        if (response) {
            triggerFetch()
            setShowDeleteSection(false);
        }
    }


    return (
        <div className="modal_delete_content">
            <p style={{ color: "#fff", textAlign: "center" }}>Are you sure to unassgin {investorUsers ? investorUsers.investorUser?.name : ""} from  {investorUsers ? investorUsers.investor.name : ""} ?</p>
            {/* <p style={{ fontSize: "12px", color: "#fff", textAlign: "center" }}>{investorUsers ? investorUsers.investor.name : ""}</p> */}


            <div className="modal_group_buttons">
                <button
                    className="bg-[#FF0000] hover:bg-[#FF0000] submit text-white"
                    onClick={() => {
                        handleUnAssign();
                    }}
                >
                    UnAssign
                </button>

                <button
                    className="cancel"
                    onClick={() => {
                        setShowDeleteSection(false);
                    }}
                >
                    Cancel
                </button>
            </div>
        </div>
    );
}

export default UnAssignInvestorUser;
