import { useContext } from "react"
import { AuthContext } from "../../../../../../Contexts/GlobalContexts/AuthContext"
import { LayoutContext } from "../../../../../../Contexts/GlobalContexts/LayoutContext"
import { Image } from "../../../../Elements/Image/Image"

import './SidebarProfile.css'

function SidebarProfile() {
    const { employee } = useContext(AuthContext)
    const { expandSidebar } = useContext(LayoutContext)

    return (
        <div className="user_wrapper">
            <div className={`user ${expandSidebar ? 'expanded' : ''}`}>
                <div className="thumb">
                    <Image
                        imgLink={employee?.dp}
                        imgAlt={'thumb'}
                        className={''}
                    />
                </div>
                {expandSidebar &&
                    <div className="content">
                        <h6>{employee?.name}</h6>
                        <span>{employee?.email}</span>
                    </div>
                }
            </div>
        </div>
    )
}

export default SidebarProfile