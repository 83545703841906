import React from 'react';

const ViewBankDetails = ({ bankData }) => {
    return (
        <div className='flex justify-center'>
            <div className="border border-yellow-400 border-dashed rounded-md p-4 text-2xl text-white w-[500px]">
                <p className='mt-4'><span className="font-semibold my-2">Account Name:</span> WISERGATES LIMITED</p>
                <p className='mt-4'><span className="font-semibold my-2">Account Number:</span> 1042101000007536</p>
                <p className='mt-4'><span className="font-semibold my-2">Bank Name:</span> UCB</p>
                <p className='mt-4'><span className="font-semibold my-2">District Name:</span> Dhaka-South</p>
                <p className='mt-4'><span className="font-semibold my-2">Branch Name:</span> Tejgaon Branch</p>
                <p className='mt-4'><span className="font-semibold my-2">Routing Number:</span> 245264485</p>
            </div>
        </div>
    );
};

export default ViewBankDetails;