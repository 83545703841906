import { useGetInfo } from "antopolis-react-utils/hooks";
import { useAxiosInstance } from "../../../../Utilities/Hooks/useAxiosInstance";
import { Image } from "../../../Partials/Elements/Image/Image";
import { MANAGE_Company_API } from "../../../../Utilities/APIs/APIs";

function ViewCompany({ api, targetID }) {
  const axiosInstance = useAxiosInstance();

  const { info: company } = useGetInfo({
    axiosInstance: axiosInstance,
    api: api + targetID,
  });

  console.log(company);

  return (
    <div className="crud_view_content">
      {company && (
        <>
          <h1>Company Details</h1>
          <div>
            <h1>
              <strong>Name:</strong> {company.name}
            </h1>
          </div>

          <div>
            <h1>
              <strong>Status:</strong>{" "}
              {company.isActive ? "Active" : "Hidden"}
            </h1>
          </div>
        </>
      )}
    </div>
  );
}

export default ViewCompany;
