import { IconPlusFill } from 'antopolis-react-icons'
import './CreateButton.css'

export function CreateButton({ screenTopicSingular , setShowCreateForm }) {
  return (
    <button
      type='button'
      className='btn_create'
      onClick={() => {
        setShowCreateForm(true)
      }}
    >
      <IconPlusFill />
      <span>{`Create New ${screenTopicSingular}`}</span>
    </button>
  )
}