import { useContext, useEffect, useState } from "react";
import { useAxiosInstance } from "../../../../../../../Utilities/Hooks/useAxiosInstance";
import { useNavigate, useParams } from "react-router-dom";
import BackButton from "../../../../../../Partials/Elements/BackButton/BackButton";
import { CreateButton } from "../../../../../../Partials/Layout/CRUD/CreateButton/CreateButton";
import { AuthContext } from "../../../../../../../Contexts/GlobalContexts/AuthContext";
import { CRUDTable } from "../../../../../../Partials/Layout/CRUD/CRUDTable/CRUDTable";
import { CRUDTableHeader } from "../../../../../../Partials/Layout/CRUD/CRUDTableHeader/CRUDTableHeader";
import { CRUDth } from "../../../../../../Partials/Layout/CRUD/CRUDth/CRUDth";
import { ShortTextCell } from "../../../../../../Partials/Layout/CRUD/ShortTextCell/ShortTextCell";
import {
  CRUDButton,
  EditButton,
} from "../../../../../../Partials/Layout/CRUD/CRUDButtons/CRUDButtons";
import { Modal } from "../../../../../../Partials/Elements/Modal/Modal";
import { MANAGE_Investors_API } from "../../../../../../../Utilities/APIs/APIs";
import CreateInvestment from "./CreateInvestment";
import UpdateInvestment from "./UpdateInvestment";
import { takaFormatter } from "../../../../../../../Utilities/HelperFunctions/takaFormatter";
import { FaFileZipper } from "react-icons/fa6";
import { useGetInfo } from "antopolis-react-utils/hooks";
import { formatDate } from "../../../../../../../Utilities/HelperFunctions/formatDate";

function Investments({ api }) {
  const [toggleFetch, setToggleFetch] = useState(false);
  const [investments, setInvestments] = useState([]);
  const { id: investorsId } = useParams();
  const [targetID, setTargetID] = useState(null);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);

  const { employee } = useContext(AuthContext);
  const axiosInstance = useAxiosInstance();
  const navigate = useNavigate();

  const { info: investor } = useGetInfo({
    axiosInstance: axiosInstance,
    api: MANAGE_Investors_API + "getSingleInvestor/" + investorsId,
    toggleFetch,
  });

  console.log(investor);

  useEffect(() => {
    async function fetchAndSetInvestments() {
      try {
        const { data } = await axiosInstance.get(
          `${MANAGE_Investors_API}getAllInvestmentOfSingleInvestor/${investorsId}`
        );
        setInvestments(data);
      } catch (error) {
        console.error("Failed to fetch investments", error);
      }
    }
    fetchAndSetInvestments();
  }, [axiosInstance, investorsId, toggleFetch]);

  const triggerFetch = () => setToggleFetch((prev) => !prev);

  const calculateMaturityDate = (dateStr, tenureInDays) => {
    const date = new Date(dateStr);
    date.setDate(date.getDate() + tenureInDays);
    return date;
  };

  const daysToMaturity = (maturityDate) => {
    const today = new Date();
    return Math.ceil((maturityDate - today) / (1000 * 60 * 60 * 24));
  };

  const exportToCSV = () => {
    const headers = [
      "Company",
      "Amount",
      "ROI (%)",
      "Tenure Days",
      "Investment Date",
      "Maturity Date",
      "Days to Maturity",
    ];
    const rows = investments.map((investment) => {
      const maturityDate = calculateMaturityDate(
        investment.date,
        investment.tenure
      );
      return [
        investment.company.name,
        investment.amount,
        `${investment.rate}%`,
        investment.tenure,
        formatDate(investment.date),
        formatDate(maturityDate),
        daysToMaturity(maturityDate),
      ];
    });

    const csvContent =
      "data:text/csv;charset=utf-8," +
      [headers.join(","), ...rows.map((row) => row.join(","))].join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "investments.csv");
    document.body.appendChild(link);

    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="screen_wrapper">
      <div className="screen_header">
        <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
          <BackButton />
          <h1 className="screen_heading">{` ${
            investor?.name ? investor.name : ""
          }'s Investments (${investments.length} in total)`}</h1>
        </div>
        <div style={{ display: "flex", gap: "20px" }}>
          <CreateButton
            screenTopicSingular="Investment"
            setShowCreateForm={setShowCreateForm}
          />
          <CRUDButton handleClick={exportToCSV}>
            <p
              style={{
                fontSize: "16px",
                color: "#FFE09D",
                textAlign: "center",
              }}
            >
              Export To CSV
            </p>
          </CRUDButton>
        </div>
      </div>
      <CRUDTable>
        <CRUDTableHeader>
          <CRUDth th="Company" />
          <CRUDth th="Amount" />
          <CRUDth th="ROI (%)" />
          <CRUDth th="Tenure Days" />
          <CRUDth th="Investment Date" />
          <CRUDth th="Maturity Date" />
          <CRUDth th="Days to Maturity" />
          <CRUDth th="Actions" />
        </CRUDTableHeader>
        <tbody>
          {investments.map((investment) => {
            const maturityDate = calculateMaturityDate(
              investment.date,
              investment.tenure
            );
            return (
              <tr key={investment._id}>
                <td className="action_buttons_wrapper">
                  <div
                    style={{ justifyContent: "start" }}
                    className="action_buttons"
                  >
                    <p
                      style={{
                        backgroundColor: investment.company.isActive
                          ? "green"
                          : "red",
                        height: "10px",
                        width: "10px",
                        borderRadius: "50%",
                        margin: "auto 10px",
                        position: "absolute",
                        top: "50%",
                        left: "5px",
                        transform: "translateY(-50%)",
                      }}
                    ></p>
                    <p className="whitespace-nowrap">
                      {investment.company.name}
                    </p>
                  </div>
                </td>
                <ShortTextCell text={takaFormatter(investment.amount)} />
                <ShortTextCell text={`${investment.rate}%`} />
                <ShortTextCell text={investment.tenure} />
                <ShortTextCell text={formatDate(investment.date)} />
                <ShortTextCell text={formatDate(maturityDate)} />
                <ShortTextCell text={daysToMaturity(maturityDate)} />
                <td className="action_buttons_wrapper">
                  <div
                    className="action_buttons"
                    style={{ justifyContent: "center" }}
                  >
                    <EditButton
                      tooltipId="single-investor-edit-btn"
                      tooltipContent="Edit"
                      setShowUpdateForm={setShowUpdateForm}
                      targetID={investment._id}
                      setTargetID={setTargetID}
                    />
                    <CRUDButton
                      tooltipId="single-investor-document-btn"
                      tooltipContent="Manage Documents"
                      handleClick={() =>
                        navigate(`/main/investmentDocuments/${investment._id}`)
                      }
                    >
                      <FaFileZipper size={25} />
                    </CRUDButton>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </CRUDTable>

      {showUpdateForm && (
        <Modal
          extraClass="small"
          modalHeading="Update Investment"
          setShowModalContent={setShowUpdateForm}
        >
          <UpdateInvestment
            api={MANAGE_Investors_API}
            employee={employee}
            setShowEditSection={setShowUpdateForm}
            targetID={targetID}
            triggerFetch={triggerFetch}
          />
        </Modal>
      )}

      {showCreateForm && (
        <Modal
          extraClass="small"
          modalHeading="Create New Investment"
          setShowModalContent={setShowCreateForm}
        >
          <CreateInvestment
            api={MANAGE_Investors_API}
            employee={employee}
            setShowEditSection={setShowCreateForm}
            targetID={investorsId}
            triggerFetch={triggerFetch}
          />
        </Modal>
      )}
    </div>
  );
}

export default Investments;
