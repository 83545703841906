import { RiArrowLeftLine } from "react-icons/ri";
import "./BackButton.css"

function BackButton() {

    function handleGoBack() {
        window.history.back();
    };
    return (
        <button onClick={handleGoBack} className="back_btn flex items-center justify-center text-4xl transition-all group ">
            <RiArrowLeftLine className="text-black flex items-center justify-center group-hover:-translate-x-[5px] transition-all duration-150 ease-out" />
        </button>
    )
}

export default BackButton