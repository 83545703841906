import InputBoilerplate from "../InputBoilerplate"


function EmailInput({ label, value, placeholder, setState, disabled }) {
  return (
    <InputBoilerplate
      isDisabled={disabled}
      label={label}
      type={'email'}
      value={value}
      placeholder={placeholder}
      setState={setState}
    />
  )
}

export default EmailInput 