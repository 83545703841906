import React, { useState } from "react";
import InvestmentChart from "./InvestmentChart";

const ProgressBar = ({ percentage }) => (
  <div className="w-full bg-[#1e1e1e] h-2 rounded-full">
    <div
      className="h-2 rounded-full"
      style={{
        width: `${percentage}%`,
        backgroundColor: percentage >= 50 ? "#F9C51C" : "#999999",
      }}
    />
  </div>
);

const ItemRow = ({ id, name, popularity, sales }) => {
  const borderColor = popularity >= 50 ? "#F9C51C" : "#999999";
  return (
    <div className="flex items-center justify-between h-12 text-gray-300 text-2xl gap-6">
      <div className="w-1/12">{String(id).padStart(2, "0")}</div>
      <div className="w-3/12">{name}</div>
      <div className="w-4/12">
        <ProgressBar percentage={popularity} />
      </div>
      <div
        className="text-right text-yellow-400 text-lg border rounded-lg px-6 py-2"
        style={{
          borderColor: borderColor,
          color: borderColor,
        }}
      >
        {sales}%
      </div>
    </div>
  );
};

const StatsLayout = ({
  reversedNanoInvestmentData,
  reversedCompanyInvestmentData,
  totalPendingBuyTokenRequests,
  totalPendingSellTokenRequests,
  topFourInvestedNanoAssets,
  topFourInvestedCompanies,
}) => {
  const [chartData, setChartData] = useState("companies");

  const handleChange = (event) => {
    setChartData(event.target.value);
  };

  // Conditionally pass the correct reversed data based on the chartData state
  const selectedInvestmentData = chartData === "companies" 
    ? reversedCompanyInvestmentData 
    : reversedNanoInvestmentData;

  return (
    <div className="grid grid-cols-2 gap-6 mt-8">
      <div className="space-y-6">
        {/* Most Nano Assets Invested In */}
        <div className="bg-[#2c2c2c] p-6 rounded-lg shadow-lg">
          <h3 className="text-white mb-4 text-xl font-semibold tracking-wide">
            Most Nano Assets Invested In
          </h3>
          <div className="text-gray-500 text-2xl font-semibold flex justify-between">
            <div className="w-1/12 text-left">Sl No.</div>
            <div className="w-3/12 text-left">Name</div>
            <div className="w-4/12 text-left">Popularity</div>
            <div className="w-2/12 text-right">Sales</div>
          </div>
          <div className="space-y-4 mt-2">
            {topFourInvestedNanoAssets?.map((asset, index) => (
              <ItemRow
                key={asset._id || index}
                id={index + 1}
                name={asset.tokenName}
                popularity={asset.tokenSalePercentage}
                sales={asset.tokenSalePercentage.toFixed(2)}
              />
            ))}
          </div>
        </div>

        {/* Most Invested Companies */}
        <div className="bg-[#2c2c2c] p-6 rounded-lg shadow-lg">
          <h3 className="text-white mb-4 text-xl font-semibold tracking-wide">
            Most Invested Companies
          </h3>
          <div className="text-gray-500 text-2xl font-semibold flex justify-between">
            <div className="w-1/12 text-left">Sl No.</div>
            <div className="w-3/12 text-left">Name</div>
            <div className="w-4/12 text-left">Popularity</div>
            <div className="w-2/12 text-right">Sales</div>
          </div>
          <div className="space-y-4 mt-2">
            {topFourInvestedCompanies?.map((company, index) => (
              <ItemRow
                key={company.company || index}
                id={index + 1}
                name={company.company}
                popularity={company.percentage}
                sales={company.percentage.toFixed(2)}
              />
            ))}
          </div>
        </div>
      </div>

      <div className="bg-[#2c2c2c] rounded-lg shadow-lg">
        <InvestmentChart
          chartData={chartData}
          setChartData={setChartData}
          reversedNanoInvestmentData={reversedNanoInvestmentData}
          reversedCompanyInvestmentData={reversedCompanyInvestmentData}
          investmentData={selectedInvestmentData} // Pass the selected investment data
        />
      </div>
    </div>
  );
};

export default StatsLayout;
