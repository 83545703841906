import { useEffect, useState } from "react"
import { useAxiosInstance } from "../../../../../../Utilities/Hooks/useAxiosInstance"
import { useGetInfo } from "antopolis-react-utils/hooks"
import Form from "../../../../../Partials/Forms/Form"
import ShortTextInput from "../../../../../Partials/Forms/FormInputs/ShortTextInput/ShortTextInput"
import ImageInput from "../../../../../Partials/Forms/FormInputs/ImageInput/ImageInput"
import NumberInput from "../../../../../Partials/Forms/FormInputs/NumberInput/NumberInput"
import FormSubmitButton from "../../../../../Partials/Forms/FormSubmitButton/FormSubmitButton"
import { HERO_SLIDERS_API } from "../../../../../../Utilities/APIs/WebsiteContentsAPIs/WebsiteContentsAPIs"


function UpdateHeroSlider({ api, setShowUpdateForm, targetID, triggerFetch }) {

    const [text, setText] = useState('')
    const [image, setImage] = useState('')
    const [precedence, setPrecedence] = useState('')
    // const [mobileImage, setMobileImage] = useState('')

    // const { triggerFetch } = useContext(LayoutContext)
    const axiosInstance = useAxiosInstance()

    const { info: heroSlider } = useGetInfo({
        axiosInstance: axiosInstance,
        api: api + 'singleHeroSlider/' + targetID,
    })

    useEffect(() => {
        if (heroSlider) {
            setText(heroSlider.text)
            setImage(heroSlider.image)
            setPrecedence(heroSlider.precedence)
            //   setMobileImage(heroSlider.mobileImage)
        }
    }, [heroSlider])

    async function handleSubmit(e) {
        e.preventDefault()

        const itemData = new FormData()
        itemData.append('text', text)
        itemData.append('file', image)
        itemData.append('precedence', precedence)
        // itemData.append('mobileImage', mobileImage)

        const response = await axiosInstance.put(HERO_SLIDERS_API + 'updateSingleHeroSlider/' + targetID, itemData)
        if (response) {
            triggerFetch()
            setShowUpdateForm(false)
        }
    }
    return (
        <div className='crate_form'>
            <Form onSubmit={handleSubmit}>

                <ShortTextInput
                    label={`Text`}
                    value={text}
                    placeholder={`Enter Text`}
                    setState={setText}

                />
                <ImageInput
                    fieldId='1'
                    state={image}
                    setState={setImage}
                    allowUpdateImage
                >
                    Upload Image
                </ImageInput>

                {/* <ImageInput
                    fieldId='2'
                    state={mobileImage}
                    setState={setMobileImage}
                    allowUpdateImage
                >
                    Upload Image
                </ImageInput> */}

                <NumberInput
                    label={`Precedence`}
                    value={precedence}
                    placeholder={`Enter Precedence`}
                    setState={setPrecedence}

                />

                <FormSubmitButton text="Update Hero Slider" />
            </Form>
        </div>
    )
}

export default UpdateHeroSlider