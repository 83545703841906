import './Modal.css';
import { ModalHeader } from './ModalHeader/ModalHeader';

export function Modal({ modalHeading, children, extraClass, setShowModalContent }) {
    return (
        <div className={`modal ${extraClass}`}>
            <div className="modal_backdrop" onClick={() => setShowModalContent(false)}></div>
            <div className={`modal_dialog modal_dialog_centered modal_dialog_scrollable`}>
                <div className="modal_content">
                    <ModalHeader
                        modalHeading={modalHeading}
                        setShowModalContent={setShowModalContent}
                    />
                    <div className="modal_body">{children}</div>
                </div>
            </div>
        </div>
    )
}
