import InputBoilerplate from "../InputBoilerplate";

function ShortTextInput({label, value, placeholder, setState, isDisabled}) {
  return (
    <InputBoilerplate 
        label={label}
        type={'text'}
        value={value}
        placeholder={placeholder}
        setState={setState}
        isDisabled={isDisabled}
    />
  )
}

export default ShortTextInput