import { HERO_SLIDERS_API } from "../../../../../../Utilities/APIs/WebsiteContentsAPIs/WebsiteContentsAPIs";
import { useAxiosInstance } from "../../../../../../Utilities/Hooks/useAxiosInstance";

function UnArchiveHeroSlider({
    targetID,
    setShowUnArchiveSection,
    setShowModal,
    triggerFetch

}) {
    const axiosInstance = useAxiosInstance();

    // const { triggerFetch } = useContext(LayoutContext);

    async function handleDelete() {
        const response = await axiosInstance.put(HERO_SLIDERS_API + "unArchiveSingleHeroSlider/" + targetID);

        if (response) {
            triggerFetch();
            setShowUnArchiveSection(false);
        }
    }
    return (
        <div className="modal_delete_content">
            <p className="text-gray-200">Are you sure you want to unarchive this hero slider?</p>

            <div className="modal_group_buttons">
                <button
                    className="submit"
                    onClick={() => {
                        handleDelete();
                    }}
                >
                    Unarchive
                </button>

                <button
                    className="cancel"
                    onClick={() => {
                        setShowModal(false);
                        setShowUnArchiveSection(false);
                    }}
                >
                    Cancel
                </button>
            </div>
        </div>
    );
}

export default UnArchiveHeroSlider;
