import { useContext, useEffect, useState } from "react";

import CreateInvite from "./CreateInvite";
import DeleteInvite from "./DeleteInvite";
import ResendInvite from "./ResendInvite";

import { AuthContext } from "../../../../../../Contexts/GlobalContexts/AuthContext";
import { useAxiosInstance } from "../../../../../../Utilities/Hooks/useAxiosInstance";
import BackButton from "../../../../../Partials/Elements/BackButton/BackButton";
import { Modal } from "../../../../../Partials/Elements/Modal/Modal";
import { CreateButton } from "../../../../../Partials/Layout/CRUD/CreateButton/CreateButton";
import {
  DeleteButton,
  ResendButton,
} from "../../../../../Partials/Layout/CRUD/CRUDButtons/CRUDButtons";
import { CRUDTable } from "../../../../../Partials/Layout/CRUD/CRUDTable/CRUDTable";
import { CRUDTableHeader } from "../../../../../Partials/Layout/CRUD/CRUDTableHeader/CRUDTableHeader";
import { CRUDth } from "../../../../../Partials/Layout/CRUD/CRUDth/CRUDth";
import { ShortTextCell } from "../../../../../Partials/Layout/CRUD/ShortTextCell/ShortTextCell";
import { MANAGE_Employees_API } from "../../../../../../Utilities/APIs/APIs";

function InviteEmployees({ api }) {
  const [invitedEmployees, setInvitedEmployees] = useState(null);
  const [employees, setEmployees] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showResendSection, setShowResendSection] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);

  const { employee } = useContext(AuthContext);
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetInvitedEmployees() {
      const { data } = await axiosInstance.get(
        MANAGE_Employees_API + "getAllInvites"
      );
      setInvitedEmployees(data);
    }
    fetchAndSetInvitedEmployees();
  }, [axiosInstance, toggleFetch]);

  function handleClose() {
    setShowCreateForm(false);
    setShowDeleteSection(false);
    setShowResendSection(false);
  }

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }

  return (
    <div className="screen_wrapper">
      <div className="screen_header">
        <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
          <BackButton />
          <h1 className="screen_heading">{`Invited Users (${
            invitedEmployees?.length > 0 ? invitedEmployees?.length : 0
          } in total)`}</h1>
        </div>
        <CreateButton
          screenTopicSingular={"Employee Invite"}
          setShowCreateForm={setShowCreateForm}
        />
      </div>

      <CRUDTable>
        <CRUDTableHeader>
          <CRUDth th="Email Address" />
          <CRUDth th="Invited By" />
          <CRUDth th="Actions" />
        </CRUDTableHeader>
        <tbody>
          {invitedEmployees?.map((invite) => (
            <tr key={invite._id}>
              <ShortTextCell text={invite.email} />
              <ShortTextCell text={invite?.employee?.name} />

              <td className="action_buttons_wrapper">
                <div className="action_buttons">
                  <DeleteButton
                    setShowDeleteSection={setShowDeleteSection}
                    targetID={invite._id}
                    setTargetID={setTargetID}
                  />
                  <ResendButton
                    setShowResendSection={setShowResendSection}
                    targetID={invite._id}
                    setTargetID={setTargetID}
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody> 
      </CRUDTable>
      {showCreateForm && (
        <Modal
          extraClass={"small"}
          modalHeading={`Create Invite`}
          setShowModalContent={setShowCreateForm}
        >
          <CreateInvite
            api={MANAGE_Employees_API}
            employee={employee}
            setShowCreateForm={setShowCreateForm}
            triggerFetch={triggerFetch}
          />
        </Modal>
      )}
      {showDeleteSection && (
        <Modal
          extraClass={"small"}
          modalHeading={`Delete Invite`}
          setShowModalContent={setShowDeleteSection}
        >
          <DeleteInvite
            api={MANAGE_Employees_API}
            employee={employee}
            setShowDeleteSection={setShowDeleteSection}
            targetID={targetID}
            triggerFetch={triggerFetch}
          />
        </Modal>
      )}
      {showResendSection && (
        <Modal
          extraClass={"small"}
          modalHeading={`Resend Invite`}
          setShowModalContent={setShowResendSection}
        >
          <ResendInvite
            api={MANAGE_Employees_API}
            employee={employee}
            setShowResendSection={setShowResendSection}
            targetID={targetID}
          />
        </Modal>
      )}
    </div>
  );
}

export default InviteEmployees;
