import { RiLogoutBoxRLine, RiSettings2Line } from "react-icons/ri";
import { useContext } from "react";
import { SidebarItem } from "../SidebarItem/SidebarItem";
import { AuthContext } from "../../../../../../Contexts/GlobalContexts/AuthContext";
import { LayoutContext } from "../../../../../../Contexts/GlobalContexts/LayoutContext";

import "./SidebarItems.css";

function SidebarItems({ children }) {
  const { logout } = useContext(AuthContext);
  const { expandSidebar } = useContext(LayoutContext);

  return (
    <div className="nav_wrapper">
      <nav className="sidebar_nav">
        <div className="nav_items">{children}</div>
        <div className={`${expandSidebar ? "w_100" : ""} common_items`}>
          {/* <SidebarItem title='Settings' children={<RiSettings2Line />} /> */}
          <div className="item" onClick={logout} style={{ cursor: "pointer" }}>
            <a>
              <RiLogoutBoxRLine />
              {expandSidebar && <span>Log Out</span>}
            </a>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default SidebarItems;
