import { useEffect, useState } from "react";
import { useAxiosInstance } from "../../../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../../../Partials/Forms/Form";
import ShortTextInput from "../../../../Partials/Forms/FormInputs/ShortTextInput/ShortTextInput";
import EmailInput from "../../../../Partials/Forms/FormInputs/EmailInput/EmailInput";
import SwitchInput from "../../../../Partials/Forms/FormInputs/SwitchInput/SwitchInput";
import ImageInput from "../../../../Partials/Forms/FormInputs/ImageInput/ImageInput";
import FormSubmitButton from "../../../../Partials/Forms/FormSubmitButton/FormSubmitButton";


function UpdateInvestorUser({
    api,
    setShowUpdateForm,
    triggerFetch,
    targetID,
}) {
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [nameError, setNameError] = useState("");
    const [isActive, setIsActive] = useState(true);
    const [isEmailVerified, setIsEmailVerified] = useState(false);
    const [company, setCompany] = useState("");
    const [designation, setDesignation] = useState("");
    const [image, setImage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [formError, setFormError] = useState(null);

    const axiosInstance = useAxiosInstance();

    useEffect(() => {
        async function fetchInvestorData() {
            try {
                setLoading(true);
                const response = await axiosInstance.get(api + "getSingleInvestorUser/" + targetID);
                if (response && response.data) {
                    setEmail(response.data.email);
                    setName(response.data.name);
                    setIsActive(response.data.isActive);
                    setIsEmailVerified(response.data.isEmailVerified);
                    setCompany(response.data.company);
                    setDesignation(response.data.designation);
                    setImage(response.data.image);
                }
            } catch (error) {
                setFormError("Error fetching investor user data. Please try again.");
            } finally {
                setLoading(false);
            }
        }
        fetchInvestorData();
    }, [api, targetID, axiosInstance]);

    const nameRegex = /^[A-Za-z\s]+$/;

    async function handleSubmit(e) {
        e.preventDefault();
        setLoading(true);
        setFormError(null);

        let isValid = true;

        if (!name) {
            setNameError("Name is required.");
            isValid = false;
        } else if (!nameRegex.test(name)) {
            setNameError("Only alphabets are allowed for the name.");
            isValid = false;
        } else {
            setNameError("");
        }

        if (!isValid) {
            setLoading(false);
            return;
        }

        const formData = new FormData();
        formData.append("email", email);
        formData.append("name", name);
        formData.append("isActive", isActive);
        formData.append("isEmailVerified", isEmailVerified);
        formData.append("company", company);
        formData.append("designation", designation);
        if (image) {
            formData.append("image", image);
        }

        try {
            const response = await axiosInstance.put(api + "updateInvestorUser/" + targetID, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });

            if (response) {
                setShowUpdateForm(false);
                triggerFetch();
            }
        } catch (error) {
            setFormError("Error updating the investor user. Please try again.");
        } finally {
            setLoading(false);
        }
    }

    return (
        <Form onSubmit={handleSubmit}>
            {formError && <div className="text-red-500 mb-4">{formError}</div>}

            <ShortTextInput
                label={"Name"}
                value={name}
                placeholder={"Please Enter a Name"}
                setState={setName}
                disabled={loading}
            />
            {nameError && <p className="error-text text-red-400">{nameError}</p>}

            <EmailInput
                disabled
                label={"Email"}
                value={email}
                placeholder={"Please Enter an Email"}
                setState={setEmail}
            />
            <ShortTextInput
                label={"Company"}
                value={company}
                placeholder={"Please Enter Company Name"}
                setState={setCompany}
                disabled={loading}
            />
            <ShortTextInput
                label={"Designation"}
                value={designation}
                placeholder={"Please Enter Designation"}
                setState={setDesignation}
                disabled={loading}
            />

            {/* <SwitchInput
                label={"Is Active?"}
                value={isActive}
                checked={isActive}
                toggleSwitch={() => setIsActive(!isActive)}
                setState={setIsActive}
                disabled={loading}
            /> */}
            <ImageInput
                allowUpdateImage
                label={"Profile Image"}
                state={image}
                setState={setImage}
                fieldId={"Investor Image"}
                disabled={loading}
            >
                Profile Picture
            </ImageInput>

            <FormSubmitButton text={loading ? "Updating..." : "Update Investor User"} disabled={loading} />
        </Form>
    );
}

export default UpdateInvestorUser;
