import { useState } from "react"
import { useAxiosInstance } from "../../../../../../Utilities/Hooks/useAxiosInstance"
import Form from "../../../../../Partials/Forms/Form"
// import ShortTextInput from "../../../../../Partials/Forms/FormInputs/ShortTextInput/ShortTextInput"
import ImageInput from "../../../../../Partials/Forms/FormInputs/ImageInput/ImageInput"
import NumberInput from "../../../../../Partials/Forms/FormInputs/NumberInput/NumberInput"
import FormSubmitButton from "../../../../../Partials/Forms/FormSubmitButton/FormSubmitButton"
import { HERO_SLIDERS_API } from "../../../../../../Utilities/APIs/WebsiteContentsAPIs/WebsiteContentsAPIs"
import ShortTextInput from "../../../../../Partials/Forms/FormInputs/ShortTextInput/ShortTextInput"

function CreateHeroSlider({ setShowCreateForm, triggerFetch }) {

    // const [link, setLink] = useState('')
    const [file, setFile] = useState('')
    const [precedence, setPrecedence] = useState('')
    const [text, setText] = useState('')

    // const { triggerFetch } = useContext(LayoutContext)
    const axiosInstance = useAxiosInstance()


    async function handleSubmit(e) {
        e.preventDefault()

        const itemData = new FormData()
        // itemData.append('link', link)
        itemData.append('file', file)
        itemData.append('precedence', precedence)
        itemData.append('text', text)

        const response = await axiosInstance.post(HERO_SLIDERS_API + "/addHeroSlider", itemData)
        if (response) {
            triggerFetch()
            setShowCreateForm(false)
        }
    }
    return (
        <div className='crate_form'>
            <Form onSubmit={handleSubmit}>

                <ShortTextInput
                    label={`Text`}
                    value={text}
                    placeholder={`Enter Text`}
                    setState={setText}

                />
                <ImageInput
                    fieldId='1'
                    state={file}
                    setState={setFile}
                    allowCreateImage
                >
                    Upload Image
                </ImageInput>

                {/* <ImageInput
                    fieldId='2'
                    state={mobileImage}
                    setState={setMobileImage}
                    allowCreateImage
                >
                    Upload Image
                </ImageInput> */}

                <NumberInput
                    label={`Precedence`}
                    value={precedence}
                    placeholder={`Enter Precedence`}
                    setState={setPrecedence}

                />


                <FormSubmitButton text="create Hero Slider" />
            </Form>
        </div>
    )
}

export default CreateHeroSlider