import Form from "../../../../Partials/Forms/Form"
import EmailInput from "../../../../Partials/Forms/FormInputs/EmailInput/EmailInput"
import FormSubmitButton from "../../../../Partials/Forms/FormSubmitButton/FormSubmitButton"

function ForgotPasswordForm({ handleSubmit, email, setEmail }) {
    return (
        <Form
            onSubmit={handleSubmit}
        >
            <EmailInput
                label='Email'
                value={email}
                placeholder='Please Enter your Email'
                setState={setEmail}
            />
            <FormSubmitButton text='Continue' />

        </Form>
    )
}

export default ForgotPasswordForm