import Navbar from "./Navbar/Navbar"
import Sidebar from "./Sidebar/Sidebar"
import { SidebarContextProvider } from "./SidebarContext"

function SideAndNavBar() {
    return (
        <SidebarContextProvider>
            <Navbar />
            <Sidebar  />
        </SidebarContextProvider>
    )
}

export default SideAndNavBar