import React from 'react'
import { GiToken } from 'react-icons/gi'
import { RiGroupLine, RiHomeOfficeLine } from 'react-icons/ri'
import { Link } from 'react-router-dom'

const Topbar = () => {
  return (
    <div className="flex items-center justify-between">
      <div>
        <h2 className="text-white text-[80px]">Dashboard</h2>
      </div>

      {/* Right side boxes */}
      <div className="flex gap-4 text-white">

        {/* Working Capital Box */}
        <Link to="/main/WorkingCapital" className="flex bg-[#2c2c2c] rounded-2xl justify-center items-center gap-6 hover:bg-[#404040] transition-all border-[#383838] border-2 px-16 py-8">
          <RiHomeOfficeLine className="text-5xl" />
          <p className="text-3xl">Working Capital</p>
        </Link>

        {/* Nano Assets Box */}
        <Link to="/main/NanoAsset" className="flex bg-[#2c2c2c] rounded-2xl justify-center items-center gap-6 hover:bg-[#404040] transition-all border-[#383838] border-2 px-16 py-8">
          <GiToken className="text-5xl" />
          <p className="text-3xl">Nano Asset</p>
        </Link>

        {/* Employees Box */}
        <Link to="/main/Employees" className="flex bg-[#2c2c2c] rounded-2xl justify-center items-center gap-6 hover:bg-[#404040] transition-all border-[#383838] border-2 px-16 py-8">
          <RiGroupLine className="text-5xl" />
          <p className="text-3xl">Employees</p>
        </Link>

      </div>
    </div>
  )
}

export default Topbar
