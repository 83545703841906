import { IconClose } from 'antopolis-react-icons'
import './ModalHeader.css'

export function ModalHeader({ modalHeading, setShowModalContent }) {
  return (
    <div className='modal_header'>
      <h1 className="modal_heading">{modalHeading}</h1>
      <button type='button' className='modal_close' onClick={() => setShowModalContent(false)}>
        <IconClose />
      </button>
    </div>
  )
}