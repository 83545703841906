import React, { useContext, useState, useMemo } from "react";
import { useGetInfo } from "antopolis-react-utils/hooks";
import { useAxiosInstance } from "../../../../../../Utilities/Hooks/useAxiosInstance";
import { CRUDTable } from "../../../../../Partials/Layout/CRUD/CRUDTable/CRUDTable";
import { CRUDTableHeader } from "../../../../../Partials/Layout/CRUD/CRUDTableHeader/CRUDTableHeader";
import { CRUDth } from "../../../../../Partials/Layout/CRUD/CRUDth/CRUDth";
import { ShortTextCell } from "../../../../../Partials/Layout/CRUD/ShortTextCell/ShortTextCell";
import { AuthContext } from "../../../../../../Contexts/GlobalContexts/AuthContext";
import BackButton from "../../../../../Partials/Elements/BackButton/BackButton";
import { CRUDButton, EditButton } from "../../../../../Partials/Layout/CRUD/CRUDButtons/CRUDButtons";
import { Modal } from "../../../../../Partials/Elements/Modal/Modal";
import CreateInvestor from "./CreateInvestor";
import { MANAGE_Investors_API } from "../../../../../../Utilities/APIs/APIs";
import { CreateButton } from "../../../../../Partials/Layout/CRUD/CreateButton/CreateButton";
import UpdateInvestor from "./UpdateInvestor";
import { useNavigate } from "react-router-dom";

import "./wc_investor.css";
import { BsBriefcase, BsBriefcaseFill, BsFileEarmarkBarGraph } from "react-icons/bs";
import { BiTrash } from "react-icons/bi";
import ArchiveInvestor from "./ArchiveInvestor";
import SelectInput from "../../../../../Partials/Forms/FormInputs/SelectInput/SelectInput";
import SelectOption from "../../../../../Partials/Forms/FormInputs/SelectInput/SelectOption/SelectOption";
import { TbTrashOff } from "react-icons/tb";
import UnArchiveInvestor from "./UnArchiveInvestor";

const exportToCSV = (investors) => {
  const headers = ["Name", "Email Address", "Status"];
  const rows = investors.map(investor => [
    investor.name,
    investor.email,
    investor.isActive ? "Active" : "Inactive"
  ]);

  const csvContent =
    "data:text/csv;charset=utf-8," +
    [headers.join(","), ...rows.map(row => row.join(","))].join("\n");

  const encodedUri = encodeURI(csvContent);
  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", "investors.csv");
  document.body.appendChild(link);

  link.click();
  document.body.removeChild(link);
};

function Investors({ api }) {
  const [toggleFetch, setToggleFetch] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showArchiveSection, setShowArchiveSection] = useState(false);
  const [showUnArchiveSection, setShowUnArchiveSection] = useState(false);
  const [filter, setFilter] = useState("active");


  const { employee } = useContext(AuthContext);
  const axiosInstance = useAxiosInstance();
  const { info: investors } = useGetInfo({
    axiosInstance: axiosInstance,
    api: api + "getAllInvestors",
    toggleFetch,
  });

  const navigate = useNavigate();

  const handleRowClick = (e, investor) => {
    const isButtonClick = e.target.closest(".wc_investor_action_buttons");
    if (!isButtonClick) {
      navigate("/main/investments/" + investor._id);
    }
  };

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }


  const filteredInvestors = useMemo(() => {
    if (!investors) return [];
    return investors.filter((investor) =>
      filter === "active" ? investor.isActive : !investor.isActive
    );
  }, [investors, filter]);

  const sortedInvestors = useMemo(() => {
    if (!filteredInvestors) return [];
    return filteredInvestors
      .slice()
      .sort((a, b) => (a.name || "").localeCompare(b.name || ""));
  }, [filteredInvestors]);


  const handleFilterChange = (value) => {
    setFilter(value);
  };

  return (
    <div className="screen_wrapper">
      <div className="screen_header">
        <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
          <BackButton />
          <h1 className="screen_heading">{`WC Investors (${investors?.length || 0} in total)`}</h1>
        </div>
        <SelectInput
          label={""}
          value={filter}
          placeholder={"Please Select Filter"}
          setState={handleFilterChange}
        >
          <SelectOption optionValue={"active"} optionText={"Active"} />
          <SelectOption optionValue={"archived"} optionText={"Archived"} />
          {/* <SelectOption optionValue={"all"} optionText={"All"} /> */}
        </SelectInput>
        <div style={{ display: "flex", gap: "20px" }}>
          <CreateButton
            screenTopicSingular={"WC Investor"}
            setShowCreateForm={setShowCreateForm}
          />
          {/* <CRUDButton handleClick={() => exportToCSV(sortedInvestors)}>
            <p style={{ fontSize: "16px", color: "#FFE09D", textAlign: "center" }}>Export To CSV</p>
          </CRUDButton> */}
        </div>
      </div>
      <CRUDTable>
        <CRUDTableHeader>
          <CRUDth align={"left"} th="Name" />
          <CRUDth th="Phone" />
          <CRUDth th="Email Address" />
          {/* <CRUDth th="Status" /> */}
          <CRUDth th="Actions" />
        </CRUDTableHeader>
        <tbody>
          {sortedInvestors &&
            sortedInvestors.map((investor) => (
              <tr
                key={investor._id}
                className="investor_link"
                onClick={(e) => handleRowClick(e, investor)}
              >
                <td>
                  <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                    <img
                      style={{ height: "30px", width: "30px", borderRadius: "50%" }}
                      src={investor.image ? process.env.REACT_APP_SPACES_URL + investor.image : `https://ui-avatars.com/api/?name=${investor?.name}&background=random&length=1`}
                      alt=""
                    />
                    <p>{investor.name}</p>
                  </div>
                </td>
                <ShortTextCell text={investor.phone} />
                <ShortTextCell text={investor.email} />
                {/* <ShortTextCell
                  text={investor.isActive ? "Active" : "Inactive"}
                /> */}
                <td className="action_buttons_wrapper">
                  <div className="action_buttons wc_investor_action_buttons">
                    <EditButton
                      tooltipId="investor-edit-btn"
                      tooltipContent="Edit"
                      setShowUpdateForm={setShowUpdateForm}
                      targetID={investor._id}
                      setTargetID={setTargetID}
                    />
                    <CRUDButton
                      tooltipId="investor-graph-btn"
                      tooltipContent="View Graph"
                      handleClick={() => navigate(`/main/investmentsGraph/${investor._id}`)}>
                      <BsFileEarmarkBarGraph size={25} />
                    </CRUDButton>
                    <CRUDButton
                      tooltipId="investor-investmentUser-btn"
                      tooltipContent="Manage Investment User"
                      handleClick={() => navigate(`/main/investors/investorUsers/${investor._id}`)}>
                      <BsBriefcaseFill size={25} />
                    </CRUDButton>

                    {
                      investor.isActive && <CRUDButton
                        tooltipId="investor-archive-btn"
                        tooltipContent="Archive"
                        handleClick={() => {
                          setShowArchiveSection(true);
                          setTargetID(investor._id);
                        }}
                      >
                        <div className="text-red-500 flex items-center justify-center">
                          <BiTrash size={25} />
                        </div>
                      </CRUDButton>
                    }
                    {
                      !investor.isActive && <CRUDButton
                        tooltipId="investor-unarchive-btn"
                        tooltipContent="Active Investor"
                        handleClick={() => {
                          setShowUnArchiveSection(true);
                          setTargetID(investor._id);
                        }}
                      >
                        <div className="flex items-center justify-center">
                          <TbTrashOff size={25} />
                        </div>
                      </CRUDButton>
                    }




                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </CRUDTable>

      {showCreateForm && (
        <Modal
          extraClass={"small"}
          modalHeading={`Create Investor`}
          setShowModalContent={setShowCreateForm}
        >
          <CreateInvestor
            api={MANAGE_Investors_API}
            employee={employee}
            setShowCreateForm={setShowCreateForm}
            triggerFetch={triggerFetch}
          />
        </Modal>
      )}

      {showUpdateForm && (
        <Modal
          extraClass={"small"}
          modalHeading={`Update Investor`}
          setShowModalContent={setShowUpdateForm}
        >
          <UpdateInvestor
            api={MANAGE_Investors_API}
            employee={employee}
            setShowUpdateForm={setShowUpdateForm}
            targetID={targetID}
            triggerFetch={triggerFetch}
          />
        </Modal>
      )}

      {
        showArchiveSection &&
        <Modal
          extraClass={"small"}
          modalHeading={`Archive Investor`}
          setShowModalContent={setShowArchiveSection}
        >
          <ArchiveInvestor
            api={MANAGE_Investors_API}
            employee={employee}
            setShowArchiveSection={setShowArchiveSection}
            targetID={targetID}
            triggerFetch={triggerFetch}
          />
        </Modal>
      }

      {
        showUnArchiveSection &&
        <Modal
          extraClass={"small"}
          modalHeading={`Unarchive Investor`}
          setShowModalContent={setShowUnArchiveSection}
        >
          <UnArchiveInvestor
            api={MANAGE_Investors_API}
            employee={employee}
            setShowUnArchiveSection={setShowUnArchiveSection}
            targetID={targetID}
            triggerFetch={triggerFetch}
          />
        </Modal>
      }
    </div>
  );
}

export default Investors;
