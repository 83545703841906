import React from "react";
import {
  RiGroup2Line,
  RiGroupLine,
  RiPercentFill,
  RiPriceTag2Fill,
  RiTruckFill,
  RiUserShared2Line,
} from "react-icons/ri";
import { GiTicket } from "react-icons/gi";
import { ScreenHolder } from "../../../../Partials/Layout/ScreenHolder/ScreenHolder";
import { NavCardList } from "../../../../Partials/Layout/NavCardList/NavCardList";
import { NavCard } from "../../../../Partials/Layout/NavCardList/NavCard/NavCard";

function InvestorScreen() {
  return (
    <ScreenHolder>
      <NavCardList numOfCards={"two"}>
        <NavCard cardName={"Investors"} navCardLink="/main/investors">
          <RiGroupLine />
        </NavCard>
        <NavCard
          cardName={"Investor Invites"}
          navCardLink="/main/investorInvites"
        >
          <RiUserShared2Line />
        </NavCard>
      </NavCardList>
    </ScreenHolder>
  );
}

export default InvestorScreen;
