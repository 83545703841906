import './FormSubmitButton.css';

function FormSubmitButton({ text, disabled }) {
  return (
    <div className="btn_wrapper">
      <button
        type="submit"
        className={`submit w_100 ${disabled ? 'btn_text disabled' : ''}`}
        disabled={disabled}
      >
        {text}
      </button>
    </div>
  );
}

export default FormSubmitButton;
