import { RiGroupLine, RiUserShared2Line } from "react-icons/ri";
import { NavCard } from "../../../../Partials/Layout/NavCardList/NavCard/NavCard";
import { NavCardList } from "../../../../Partials/Layout/NavCardList/NavCardList";
import { ScreenHolder } from "../../../../Partials/Layout/ScreenHolder/ScreenHolder";


function TokenInvestorScreen() {
  return (
    <ScreenHolder>
      <NavCardList numOfCards={"two"}>
        <NavCard cardName={"Nano Assets Investors"} navCardLink="/main/nanoAssetInvestors">
          <RiGroupLine />
        </NavCard>
        <NavCard
          cardName={"Nano Assets Investor Invites"}
          navCardLink="/main/nanoAssetInvestorInvites"
        >
          <RiUserShared2Line />
        </NavCard>
      </NavCardList>
    </ScreenHolder>
  );
}

export default TokenInvestorScreen;
