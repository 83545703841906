import { useContext } from "react";
import { useResgister } from "antopolis-react-utils/hooks/auth";
import useAxiosAuthInstance from "../../../../../Utilities/Hooks/axiosAuthInstance";
import { useParams } from "react-router-dom";
import ShortTextInput from "../../../../Partials/Forms/FormInputs/ShortTextInput/ShortTextInput";
import Form from "../../../../Partials/Forms/Form";
import FormSubmitButton from "../../../../Partials/Forms/FormSubmitButton/FormSubmitButton";
import PasswordInput from "../../../../Partials/Forms/FormInputs/PasswordInput/PasswordInput";
import { REGISTER_API } from "../../../../../Utilities/APIs/AuthAPIs/AuthAPIs";
import ImageInput from "../../../../Partials/Forms/FormInputs/ImageInput/ImageInput";
import { AuthContext } from "../../../../../Contexts/GlobalContexts/AuthContext";
import EmailInput from "../../../../Partials/Forms/FormInputs/EmailInput/EmailInput";

function RegisterForm() {
  const { setEmployee, setError } = useContext(AuthContext);
  const axiosAuthInstance = useAxiosAuthInstance();
  const { token } = useParams();

  const {
    name,
    setName,
    email,
    setEmail,
    password,
    setPassword,
    password2,
    setPassword2,
    dp,
    setDp,
    handleSubmit,
  } = useResgister({
    axiosInstance: axiosAuthInstance,
    api: REGISTER_API,
    userKey: "employee",
    setUser: setEmployee,
    setErrorMessage: setError,
    emailFromTokenApi: "getEmailFromToken/",
    token,
  });

  return (
    <Form onSubmit={handleSubmit} hasImage>
      <ShortTextInput
        label="Name"
        value={name}
        placeholder="Please Enter your Name"
        setState={setName}
      />
      <EmailInput
        label="Email"
        value={email}
        placeholder="Please Enter your Email"
        setState={setEmail}
      />
      <PasswordInput
        label="Password"
        value={password}
        placeholder="Please Enter your Password"
        setState={setPassword}
      />
      <PasswordInput
        label="Confirm Password"
        value={password2}
        placeholder="Please Confirm your Password"
        setState={setPassword2}
      />
      <ImageInput
        allowCreateImage
        state={dp}
        setState={setDp}
        fieldId={"employeeDp"}
        children={"Display Picture"}
      />
      <FormSubmitButton text="Register" />
    </Form>
  );
}

export default RegisterForm;
