import "./ScreenHolder.css";
import { useContext } from "react";
import { AuthContext } from "../../../../Contexts/GlobalContexts/AuthContext";

function ScreenHolder({ children }) {

  const { employee } = useContext(AuthContext)

  if (employee) {
    return (
      <div className="screen_holder_wrapper">
        <div className={"screen_holder" + employee ? 'employee_holder' : ''}>{children}</div>
      </div>
    );
  } else {
    return <div className="screen_holder">{children}</div>;
  }
}

export { ScreenHolder };
