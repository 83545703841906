import { createContext, useState } from "react";

const LayoutContext = createContext({})


function LayoutContextProvider({ children }) {
    
    const [isLoading, setIsLoading] = useState(false)
    const [expandSidebar, setExpandSidebar] = useState(false);
    const [toggleFetch, setToggleFetch] = useState(false);

    function triggerFetch() {
        setToggleFetch((prevState) => !prevState);
      }

    return (
        <LayoutContext.Provider
            value={{
                isLoading,
                setIsLoading,
                expandSidebar,
                setExpandSidebar,
                toggleFetch,
                triggerFetch
            }}
        >
            {children}
        </LayoutContext.Provider>
    )
}

export {
    LayoutContextProvider,
    LayoutContext
}