import { useGetInfo } from "antopolis-react-utils/hooks";
import { useAxiosInstance } from "../../../../../Utilities/Hooks/useAxiosInstance";
import { MANAGE_Tokens_API } from "../../../../../Utilities/APIs/APIs";

function ArchiveTokenModal({
  api,
  targetID,
  setShowArchiveSection,
  setShowModal,
  triggerFetch,
}) {
  const axiosInstance = useAxiosInstance();

  const { info: token } = useGetInfo({
    axiosInstance: axiosInstance,
    api: MANAGE_Tokens_API + "getSingleToken/" + targetID,
  });

  async function handleArchive() {
    const response = await axiosInstance.put(
      MANAGE_Tokens_API + "archiveSingleToken/" + targetID
    );

    if (response) {
      triggerFetch();
      setShowArchiveSection(false);
    }
  }
  return (
    <div className="modal_delete_content">
      <p className="text-gray-200">
        Are you sure you want to archive {token?.data?.tokenName}?
      </p>

      <div className="modal_group_buttons">
        <button
          className="submit"
          onClick={() => {
            handleArchive();
          }}
        >
          Archive
        </button>

        <button
          className="cancel"
          onClick={() => {
            setShowModal(false);
            setShowArchiveSection(false);
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  );
}

export default ArchiveTokenModal;
